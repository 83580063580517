import { Formik, FormikProps } from 'formik';
import { SectionContent } from '../../amrPipeline/common/section/SectionContent';
import { TextField } from '../../common/form-fields/TextField';
import { EmptyEvent, Events } from './EditEvents';
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { useEffect, useRef } from 'react';
import { BanksState } from '../../../types/state/BanksState';
import { Grid } from '../../grid';
import { gridActions } from '../../../actions';
import { constants, gridColumns } from '../../../constants';
import { banksActions } from '../../../actions/banks.actions';
import { Bank } from '../../../types/banks/Bank';
import { CompanyEvent } from '../../../types/banks/CompanyEvent';
import { isNil } from 'lodash';
import { BrokerDealerSave } from '../../../types/banks/BrokerDealerSave';
import { BanksValidationSchema } from './BanksValidationSchema';
import { useAppDispatch } from '../../../effects/useAppDispatch';
import { useHistory } from 'react-router';
import { RouteLeavingGuard } from '../../routing';

interface Form {
    events: CompanyEvent[];
    headOfDesk?: BrokerDealerSave;
    headOfTrading?: BrokerDealerSave;
}

interface BanksEditPageProps {
    companyReferenceName: string;
    bank: Bank;
    setSubmitHandler: (fn: () => Promise<void>) => void;
}

export const BankEditPage = ({ companyReferenceName, bank, setSubmitHandler }: BanksEditPageProps) => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const formRef = useRef<FormikProps<any>>(null);
    const { editBank } = useSelector<AppState, BanksState>(state => state.banks);

    useEffect(() => {
        dispatch(banksActions.editBank());

        return () => {
            dispatch(banksActions.editBankReset());
            dispatch(gridActions.reset());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isNil(editBank)) {
            return;
        }

        dispatch(banksActions.editBank());
        dispatch(gridActions.reset());
        dispatch(gridActions.init(editBank.contacts, gridColumns.banksContacts(), constants.gridRowsLimit, 10, false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNil(editBank)]);

    useEffect(() => {
        if (formRef.current) {
            setSubmitHandler(formRef.current.submitForm);
        }
    }, [setSubmitHandler]);

    if (!editBank) {
        return null;
    }

    const handleSubmit = (values: Form) => {
        dispatch(gridActions.validate());
        dispatch(banksActions.updateBank(bank.referenceName, values));
    };

    const initialValuesWithEvents = editBank.events.length
        ? editBank
        : {
              ...editBank,
              events: [
                  EmptyEvent
              ],
          };

    return (
        <Formik
            initialValues={initialValuesWithEvents}
            innerRef={formRef}
            validationSchema={BanksValidationSchema}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue, values, dirty, isSubmitting }) => (
                <>
                    <RouteLeavingGuard
                        navigate={(pathname: string) => history.push(pathname)}
                        shouldBlockNavigation={() => !!dirty && !isSubmitting}
                    />
                    <div className="tabs-data tabs-data-padding">
                        <SectionContent title="Contacts">
                            <div className="content-item-wrap">
                                <h3>Head of Desk</h3>
                                <div className="content-row">
                                    <div className="content-col">
                                        <TextField
                                            label="First Name"
                                            name="headOfDesk.firstName"
                                            placeholder="First Name"
                                        />
                                        <TextField
                                            label="Last Name"
                                            name="headOfDesk.lastName"
                                            placeholder="Last Name"
                                        />
                                        <TextField label="Email" name="headOfDesk.email" placeholder="Email Address" />
                                    </div>
                                    <div className="content-col">
                                        <TextField
                                            label="Office"
                                            name="headOfDesk.officePhoneNumber"
                                            placeholder="Office Number"
                                        />
                                        <TextField
                                            label="Mobile"
                                            name="headOfDesk.mobilePhoneNumber"
                                            placeholder="Mobile Number"
                                        />
                                        <TextField
                                            label="Primary Distribution List"
                                            name="headOfDesk.distributionListEmail"
                                            placeholder="Primary Distribution List"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="content-item-wrap">
                                <h3>Head of Trading</h3>
                                <div className="content-row">
                                    <div className="content-col">
                                        <TextField
                                            label="First Name"
                                            name="headOfTrading.firstName"
                                            placeholder="First Name"
                                        />
                                        <TextField
                                            label="Last Name"
                                            name="headOfTrading.lastName"
                                            placeholder="Last Name"
                                        />
                                        <TextField
                                            label="Email"
                                            name="headOfTrading.email"
                                            placeholder="Email Address"
                                            required={!!values.headOfTrading?.firstName || !!values.headOfTrading?.lastName}
                                        />
                                    </div>
                                    <div className="content-col">
                                        <TextField
                                            label="Office"
                                            name="headOfTrading.officePhoneNumber"
                                            placeholder="Office Number"
                                        />
                                        <TextField
                                            label="Mobile"
                                            name="headOfTrading.mobilePhoneNumber"
                                            placeholder="Mobile Number"
                                        />
                                        <TextField
                                            label="Secondary Distribution List"
                                            name="headOfTrading.distributionListEmail"
                                            placeholder="Secondary Distribution List"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="section-banks-users">
                                <Grid dataUploadDisabled />
                            </div>
                        </SectionContent>
                        <Events
                            companyReferenceName={companyReferenceName}
                            events={values.events}
                            onChange={events => setFieldValue('events', events)}
                        />
                    </div>
                </>
            )}
        </Formik>
    );
};

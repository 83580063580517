import { useContext, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { routes } from "../../constants";
import { AppState } from "../../types/state/AppState";
import { useHistory, useParams } from "react-router";
import { TabList, TabItem } from '../bidding/common/tab-list';
import { TabContent } from '../amrPipeline/common/tabs/TabContent';
import { Preloader } from '../common';
import { TabBody } from '../amrPipeline/common/tabs/TabBody';
import { BanksTabType, BanksTabTypes } from '../../types/banks/BanksTabType';
import { ContactsAndEvents } from './tabs/contacts-and-events/ContactsAndEvents';
import { Primary } from './tabs/primary/Primary';
import { Secondary } from './tabs/secondary/Secondary';
import { Analytics } from './tabs/analytics/Analytics';
import BanksContext from './banksContext';
import { Bank } from '../../types/banks/Bank';
import { banksActions } from '../../actions/banks.actions';
import { BankAccessType } from '../../types/amr-pipeline/enums/BankAccessType';

interface BanksRouteParams {
    referenceName: string;
    tab?: BanksTabType;
}

interface BankViewPageProps {
    bank: Bank;
}

const TabTypeToAccessType: Partial<Record<BanksTabType, BankAccessType>> = {
    [BanksTabType.ContactsAndEvents]: BankAccessType.ContactsEventsTab,
    [BanksTabType.Primary]: BankAccessType.PrimaryTab,
    [BanksTabType.Secondary]: BankAccessType.SecondaryTab,
};

export const BankViewPage = ({ bank }: BankViewPageProps) => {
    const { referenceName, tab } = useParams<BanksRouteParams>();
    const { hasAnalyticsAccess } = useContext(BanksContext);
    const dispatch = useDispatch();
    const history = useHistory();

    const isLoading = useSelector((state: AppState) => state.banks.isLoading);

    const selectedTab = tab || BanksTabType.ContactsAndEvents;

    useEffect(() => {
        // This is to prevent double logging, when switching between
        // managers and selected tab is not Overview
        if (bank.referenceName !== referenceName) {
            return;
        }

        if (selectedTab === BanksTabType.Analytics) {
            return;
        }

        dispatch(banksActions.logUserActivity(bank.referenceName, TabTypeToAccessType[selectedTab] as BankAccessType));
    }, [dispatch, referenceName, bank.referenceName, selectedTab]);

    const accessibleTabs = useMemo(() => BanksTabTypes.filter(tabType =>
        tabType.value === BanksTabType.Analytics ? hasAnalyticsAccess : true
    ), [hasAnalyticsAccess]);

    const renderActiveTab = () => {
        switch (tab) {
            case BanksTabType.Primary:
                return <Primary />;
            case BanksTabType.Secondary:
                return <Secondary />;
            // TODO: Second Iteration
            // case BanksTabType.SettlementAccounts:
            //     return <SettlementAccounts />;
            case BanksTabType.Analytics:
                return <Analytics />;
            default:
                return <ContactsAndEvents />;
        }
    };

    const handleTabChange = (tab: BanksTabType) => {
        return history.replace(routes.manageBanksUrl(referenceName, tab));
    };

    return (
        <>
            <TabList className="tabs-left-shift">
                {accessibleTabs.map(({ value, text }) => (
                    <TabItem
                        key={value}
                        active={tab === value}
                        title={text}
                        onClick={() => handleTabChange(value)}
                    />
                ))}
            </TabList>
            <TabContent>
                <TabBody className="tabs-data tabs-data-padding">
                    {isLoading ? <Preloader inProgress={true} /> : renderActiveTab()}
                </TabBody>
            </TabContent>
        </>
    );
};

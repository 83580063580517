import { ColumnBuilder } from '../../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { collapseStickRightColumn } from '../../../../bidding/common/table/CollapseStickRightColumn';
import {
    isNewColumn,
    createLastUpdatedColumn,
    dealNameColumn,
    dealTickerColumn,
    transactionStatusColumn,
    transactionTypeColumn,
    transactionCollateralTypeColumn,
    transactionCurrencyColumn,
    transactionSizeColumn,
    pricingDateColumn,
    closingDateColumn,
    nonCallPeriodEndColumn,
    reinvestmentPeriodEndColumn,
    dealOptionsColumn,
    dealIntexColumn,
    createGoToColumn,
    createRelatedTransactionsColumn,
    nonCallPeriodEndYrsColumn,
    reinvestmentPeriodEndYrsColumn,
    outOfNcColumn,
    outOfRiColumn,
} from '../../../../clo-managers/tabs/deals/columns';
import { PipelineColumn } from '../../../../amrPipeline/types/PipelineColumn';
import { SORTING_TYPE, constants, pipelineColumnLabels } from '../../../../../constants';
import { IColumnDefinition } from '../../../../bidding/common/table/types/ColumnDefinition';
import { OriginatingTransaction } from '../../../../../types/amr-pipeline/models/OriginatingTransaction';
import { ManagerLink } from '../../../../amrPipeline/common/ManagerLink';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';

export const dealColumns = [
    isNewColumn,
    createLastUpdatedColumn([SubscriptionFeature.DealerProfileDeals, SubscriptionFeature.IssuanceMonitorHistoricalData]),
    dealNameColumn,
    dealTickerColumn,
    {
        columnKey: PipelineColumn.manager,
        renderColumnHeaderContent: () => pipelineColumnLabels[PipelineColumn.manager],
        renderColumnContent: (transaction, context) => {
            const { onManagerClick } = context;
            const { collateralManager } = transaction;

            if (!collateralManager) {
                return constants.emptyPlaceholder;
            }

            return (
                <ManagerLink
                    referenceName={collateralManager.referenceName}
                    legalName={collateralManager.legalName}
                    onClick={onManagerClick}
                />
            );
        },
        sortingField: 'collateralManager',
        sortingType: SORTING_TYPE.company,
        className: 'data-list-cell-xl',
    } as IColumnDefinition<OriginatingTransaction>,
    transactionStatusColumn,
    transactionTypeColumn,
    transactionCollateralTypeColumn,
    transactionCurrencyColumn,
    transactionSizeColumn,
    pricingDateColumn,
    closingDateColumn,
    nonCallPeriodEndColumn,
    nonCallPeriodEndYrsColumn,
    outOfNcColumn,
    reinvestmentPeriodEndColumn,
    reinvestmentPeriodEndYrsColumn,
    outOfRiColumn,
    collapseStickRightColumn,
    dealOptionsColumn,
    dealIntexColumn,
    createGoToColumn([SubscriptionFeature.DealerProfileDeals, SubscriptionFeature.IssuanceMonitorHistoricalData]),
    createRelatedTransactionsColumn([SubscriptionFeature.DealerProfileDeals, SubscriptionFeature.IssuanceMonitorMarketingHistory]),
].map((c) => new ColumnBuilder(c));


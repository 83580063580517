import { useDispatch } from "react-redux";
import { KWatchNewsWidget } from '../../../dashboard/k-watchNews/KWatchNewsWidget';
import { banksActions } from '../../../../actions/banks.actions';
import { BankAccessType } from '../../../../types/amr-pipeline/enums/BankAccessType';
import { useBankNews } from '../../hooks';

interface BanksKWatchNewsWidgetProps {
    companyReferenceName: string;
}

export const BanksKWatchNewsWidget = ({ companyReferenceName }: BanksKWatchNewsWidgetProps) => {
    const dispatch = useDispatch();

    const { requestState, news } = useBankNews(companyReferenceName);

    if (!news.length) {
        return null;
    }

    const handleAllNewsClick = () => {
        if (companyReferenceName) {
            dispatch(banksActions.logUserActivity(companyReferenceName, BankAccessType.ContactsEventsAllNews));
        }
    };

    return (
        <div className="dashboard">
            <div className="dashboard-row flex-row">
                <KWatchNewsWidget news={news} requestState={requestState} className="full-size" onAllNewsClick={handleAllNewsClick} />
            </div>
        </div>
    );
};

import * as React from 'react';
import classNames from 'classnames';
import { formatUtils, numericUtils, stringUtils } from '../../../utils';
import { FormError, Checkbox } from '../../controls';
import { constants } from '../../../constants';
import { BidLevelDelta } from '../../common/bidding/BidLevelDelta';
import { BlockedFeatureContext } from '../../access/BlockedFeatureContext';
import { RevertBidButton } from "../../common/RevertBidButton";
import IconSVG from "../../../styles/svg-icons";
import { OnHoverTooltip } from "../../common";

interface Props {
    originalBid: number;
    bid?: string;
    axed: boolean;
    final: boolean;
    disabled?: boolean;
    error?: string;
    bidAttributesVisible: boolean;
    renderCommission: () => React.ReactNode;
    onBidChange: (bid: string | undefined, axed: boolean, final: boolean) => void;
    onRevert?: () => void;
    onLeave?: () => void;
}

export function Bid({
    originalBid,
    bid,
    axed,
    final,
    disabled = false,
    bidAttributesVisible,
    error = '',
    renderCommission,
    onBidChange,
    onRevert,
    onLeave }: Props) {
    const isLevelChanged = originalBid
        ? originalBid !== numericUtils.numberOrDefault(bid)
        : !!Number(bid) && !stringUtils.isNullOrWhiteSpace(bid);
    const isBlocked = React.useContext(BlockedFeatureContext);
    const [hasFocus, setHasFocus] = React.useState(false);
    const levelInputRef = React.useRef<HTMLInputElement>(null);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
        setHasFocus(true);
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value;
        const changedBidValue = rawValue ? rawValue.replace(/\s/g, '') : rawValue;

        if (changedBidValue && !(constants.bidNumber.test(changedBidValue))) {
            return null;
        }

        onBidChange(changedBidValue, axed, final);
    };
    const handleBlur = () => {
        setHasFocus(false);

        if (bid != null && numericUtils.isNumber(bid)) {
            const formatted = formatUtils.formatBid(+bid);
            onBidChange(formatted, axed, final);
        } else {
            const originalOrEmpty = originalBid ? formatUtils.formatBid(originalBid) : '';
            onBidChange(originalOrEmpty, axed, final);
        }

        if (onLeave) {
            onLeave();
        }
    }
    const handleRevert = () => {
        if (onRevert) {
            onRevert();
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            setHasFocus(false);
            levelInputRef.current?.blur();
        }
    }

    const classes = classNames(
        'display-inline',
        'form-control-wrapper', {
        'form-control-changed': isLevelChanged && !(disabled || isBlocked),
        'form-control-error': !!error && isLevelChanged
    });

    return (
        <div className="flex-row position-relative">
            <div className={classes}>
                <input
                    ref={levelInputRef}
                    type="text"
                    className={classNames("form-control form-control-sm", { 'is-invalid': !!error && !isLevelChanged })}
                    value={disabled || isBlocked ? formatUtils.formatBid(originalBid) : bid}
                    disabled={disabled || isBlocked}
                    maxLength={8}
                    placeholder="0.0000"
                    onFocus={handleFocus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                />
                <FormError message={error} />
            </div>
            {
                hasFocus &&
                isLevelChanged &&
                bid &&
                originalBid != null &&
                originalBid > 0 &&
                <BidLevelDelta
                    currentLevel={originalBid}
                    updatedLevel={+bid}
                    updatedLevelVisible={false}
                    tooltip={true}
                />
            }
            {isLevelChanged && typeof onRevert === 'function' && <RevertBidButton onRevert={handleRevert} />}
            <div>{renderCommission()}</div>
            <div className="bid-attributes flex-row">
                {
                    bidAttributesVisible &&
                    <>
                        <div className="checkbox-wrapper flex-item-right">
                            <OnHoverTooltip overlay="Axed">
                                <IconSVG name="axed" width={16} height={16} />
                            </OnHoverTooltip>
                            <Checkbox
                                checked={axed}
                                disabled={disabled || isBlocked}
                                onChange={e => onBidChange(bid, e.target.checked, final)}
                            />
                        </div>
                        <div className="checkbox-wrapper">
                            <OnHoverTooltip overlay="Final. Bid will be marked as final, but you will be allowed to improve afterward.">
                                <IconSVG name="final" width={16} height={16} />
                            </OnHoverTooltip>
                            <Checkbox
                                checked={final}
                                disabled={disabled || isBlocked}
                                onChange={e => onBidChange(bid, axed, e.target.checked)}
                            />
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

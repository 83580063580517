import { useSelector } from 'react-redux';
import { SectionContent } from '../../../amrPipeline/common/section/SectionContent';
import { AppState } from '../../../../types/state/AppState';
import { BanksState } from '../../../../types/state/BanksState';
import { useContext } from 'react';
import { BanksKWatchNewsWidget } from './BanksKWatchNewsWidget';
import { BankEvents } from './BankEvents';
import { ShowFor } from '../../../access';
import { roles } from '../../../../constants';
import BanksContext from '../../banksContext';
import { RequiredFeature } from '../../../access/RequiredFeature';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { BlockedFeatureContent, SubscribeLink } from '../../../access/BlockedFeatureText';
import { BankContacts } from './BankContacts';

export const ContactsAndEvents = () => {
    const { ownCompany } = useContext(BanksContext);
    const { selectedBank } = useSelector<AppState, BanksState>(state => state.banks);

    if (!selectedBank?.referenceName) {
        return null;
    }

    return (
        <div className="contacts-events-tab">
            <SectionContent title="Contacts">
                <BankContacts />
            </SectionContent>
            <ShowFor roles={roles.getAllRolesExclude(...roles.bd())} overrideRequiredFeatures={ownCompany}>
                <SectionContent title="Events" className="data-item-events">
                    <RequiredFeature
                        feature={SubscriptionFeature.DealerProfileBankEvents}
                        overrideRequiredFeatures={ownCompany}
                        renderBlockedContent={() => (
                            <BlockedFeatureContent
                                inline
                                text={<><SubscribeLink /> to see events.</>}
                                className="empty-placeholder restricted-placeholder-general"
                            />
                        )}
                    >
                        <BankEvents />
                    </RequiredFeature>
                </SectionContent>
            </ShowFor>
            <ShowFor roles={roles.getAllRolesExclude(roles.Media)}>
                <BanksKWatchNewsWidget companyReferenceName={selectedBank.referenceName} />
            </ShowFor>
        </div>
    );
};

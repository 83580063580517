import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Table } from '../../../bidding/common/table';
import { bankContactsColumns } from './contacts.columns';
import { AppState } from '../../../../types/state/AppState';
import { BanksState } from '../../../../types/state/BanksState';
import { useContext } from 'react';
import { EmptyPlaceholder } from '../../../common';
import { Contact } from '../../../../types/amr-pipeline/models/Contact';
import { BankAccessType } from '../../../../types/amr-pipeline/enums/BankAccessType';
import { banksActions } from '../../../../actions/banks.actions';
import BanksContext from '../../banksContext';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { BlockedFeatureContent, SubscribeLink } from '../../../access/BlockedFeatureText';
import { WithHiddenItems } from '../../../../types/amr-pipeline/models/WithHiddenItems';
import { ActionBlocker } from '../../../access/ActionBlocker';
import { user } from '../../../../user';
import { roles } from '../../../../constants';

export const BankContacts = () => {
    const dispatch = useDispatch();
    const { ownCompany } = useContext(BanksContext);
    const { selectedBank, syndicateContacts } = useSelector<AppState, BanksState>(state => state.banks);
    const { primaryContacts, secondaryContacts } = syndicateContacts;
    const allContactsCount = primaryContacts.items.length + secondaryContacts.items.length;
    const allHiddenContactsCount = primaryContacts.numberOfHiddenItems + secondaryContacts.numberOfHiddenItems;
    const isBrokerDealer = user.hasRoles(...roles.bd());

    if (!selectedBank?.referenceName) {
        return null;
    }

    const logColumnClick = (bankAccessType: BankAccessType) => () => {
        dispatch(banksActions.logUserActivity(selectedBank.referenceName, bankAccessType));
    };

    const renderTable = (primaryOrSecondary: 'Primary' | 'Secondary', contacts?: WithHiddenItems<Contact>, className?: string) => {
        if (!contacts?.items.length && (!contacts?.numberOfHiddenItems || isBrokerDealer)) {
            return (
                <EmptyPlaceholder
                    text={`There are no contacts for ${primaryOrSecondary} Desk yet.`}
                    className="data-list-primary-desk"
                />
            );
        }

        return (
            <ActionBlocker feature={SubscriptionFeature.DealerProfileBankContacts} overrideRequiredFeatures={ownCompany} noTooltip>
                {blocked => (
                    <div className={className}>
                        <Table
                            className={classnames("data-list data-list-striped data-list-overflow-auto")}
                            columns={bankContactsColumns}
                            dataItems={contacts.items}
                            createSecurityCustomArgs={() => ({
                                onLinkedInClick: logColumnClick(BankAccessType.ContactsEventsIn),
                                ownCompany,
                            })}
                        />
                        {blocked && !isBrokerDealer && contacts.numberOfHiddenItems > 0 && (
                            <BlockedFeatureContent
                                inline
                                className={classnames({ 'empty-placeholder primary-desk-empty': !contacts?.items.length })}
                                text={<><SubscribeLink /> to see {contacts.numberOfHiddenItems} more contact(s).</>}
                            />
                        )}
                    </div>
                )}
            </ActionBlocker>
        );
    };

    if (!allContactsCount && !allHiddenContactsCount) {
        return (
            <EmptyPlaceholder text="There are no contacts yet"/>
        );
    }

    return (
        <>
            <h3>Primary Desk</h3>
            {renderTable('Primary', primaryContacts, 'content-item-wrap')}
            <h3>Secondary Desk</h3>
            {renderTable('Secondary', secondaryContacts)}
        </>
    );
};

import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roles, routes } from '../../../../constants';
import { Currency } from '../../../../types/enums/Currency';
import { AppState } from '../../../../types/state/AppState';
import { Statistics } from './Statistics';
import { StatisticsChart } from '../../../clo-managers/tabs/overview/StatisticsChart';
import { QueryStringArgumentSerializer } from '../../../../utils/filtering/serializers/QueryStringArgumentSerializer';
import { queryStringSerializer } from '../../../../utils/filtering';
import {
    multipleCurrencies,
    transactionTypes,
    esg,
    euCompliance,
    staticDeals,
    transactionStatuses,
    arrangers,
    outOfNC,
    outOfRI,
} from '../../../../utils/filtering/serializers/serializer.definitions';
import { StatisticsChartsTransactionStatuses, StatisticsChartsTransactionTypes } from '../../../clo-managers/tabs/overview/constants';
import { BanksState } from '../../../../types/state/BanksState';
import { ManagersTransactionCount } from './managersTransactionCount/ManagersTransactionCount';
import { BankArrangerPipeline } from './arrangerPipeline/BankArrangerPipeline';
import { BankDeals } from './deals/BankDeals';
import { ShowFor } from '../../../access';
import BanksContext from '../../banksContext';
import { BankAccessType } from '../../../../types/amr-pipeline/enums/BankAccessType';
import { banksActions } from '../../../../actions/banks.actions';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';

export const Primary = () => {
    const dispatch = useDispatch();
    const { ownCompany } = useContext(BanksContext);
    const { selectedBank, aggregatedManagersCount } = useSelector<AppState, BanksState>((state: AppState) => state.banks);

    if (!selectedBank?.details) {
        return null;
    }

    const redirectToIssuanceMonitor = (qsBuilder: (...params: any) => string, bankAccessType: BankAccessType) => (...params: any) => {
        const queryString = qsBuilder(...params, bankAccessType);

        if (bankAccessType) {
            dispatch(banksActions.logUserActivity(selectedBank.referenceName, bankAccessType));
        }

        window.open(`${window.location.origin}${routes.AMRPipeline}/?${queryString}`);
    };

    const redirectToIMWithCurrency = redirectToIssuanceMonitor((currency: Currency) => {
        const searchParams = {
            arrangers: [selectedBank.referenceName],
            currency: [currency],
            transactionStatuses: StatisticsChartsTransactionStatuses,
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            arrangers(),
            multipleCurrencies(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, BankAccessType.PrimaryPieGeographicMixDoor);

    const redirectToIMWithESG = redirectToIssuanceMonitor((esgDeals: boolean) => {
        const searchParams = {
            arrangers: [selectedBank.referenceName],
            esg: esgDeals,
            transactionStatuses: StatisticsChartsTransactionStatuses,
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            arrangers(),
            esg(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, BankAccessType.PrimaryPieEsgDealsDoor);

    const redirectToIMWithEUCompliance = redirectToIssuanceMonitor((euComplianceDeals: boolean) => {
        const searchParams = {
            arrangers: [selectedBank.referenceName],
            euCompliance: euComplianceDeals,
            transactionStatuses: StatisticsChartsTransactionStatuses,
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            arrangers(),
            euCompliance(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, BankAccessType.PrimaryPieEuCompliantDoor);

    const redirectToIMWithStaticDeals = redirectToIssuanceMonitor((staticDeal: boolean) => {
        const searchParams = {
            arrangers: [selectedBank.referenceName],
            staticDeals: staticDeal,
            transactionStatuses: StatisticsChartsTransactionStatuses,
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            arrangers(),
            staticDeals(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, BankAccessType.PrimaryPieStaticDealsDoor);

    const redirectToIMWithOutOfNC = redirectToIssuanceMonitor((isOutOfNC: boolean) => {
        const searchParams = {
            arrangers: [selectedBank.referenceName],
            outOfNC: isOutOfNC,
            transactionStatuses: StatisticsChartsTransactionStatuses,
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            arrangers(),
            outOfNC(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, BankAccessType.PrimaryPieOutOfNCDoor);

    const redirectToIMWithOutOfRI = redirectToIssuanceMonitor((isOutOfRI: boolean) => {
        const searchParams = {
            arrangers: [selectedBank.referenceName],
            outOfRI: isOutOfRI,
            transactionStatuses: StatisticsChartsTransactionStatuses,
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            arrangers(),
            outOfRI(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, BankAccessType.PrimaryPieOutOfRIDoor);

    return (
        <div className="data-item-statistics">
            <div className="data-item">
                <Statistics stats={selectedBank.details} companyReferenceName={selectedBank.referenceName} />
                <div className="statistics-charts">
                    <StatisticsChart
                        title="Geographic Mix"
                        onSectionClick={redirectToIMWithCurrency}
                        values={[selectedBank.details.usdDeals, selectedBank.details.eurDeals]}
                        labels={['USD', 'EUR']}
                        colors={['#9cc4e3', '#487eb2']}
                        sections={[Currency.USD, Currency.EUR]}
                        features={[SubscriptionFeature.DealerProfileStatistics]}
                    />
                    <StatisticsChart
                        title="ESG Deals"
                        onSectionClick={redirectToIMWithESG}
                        values={[selectedBank.details.esgDeals, selectedBank.details.nonEsgDeals]}
                        labels={['ESG', 'Non-ESG']}
                        colors={['#9cc4e3', '#487eb2']}
                        sections={[true, false]}
                        features={[SubscriptionFeature.DealerProfileStatistics, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]}
                    />
                    <StatisticsChart
                        title="EU Compliant"
                        onSectionClick={redirectToIMWithEUCompliance}
                        values={[selectedBank.details.euCompliantDeals, selectedBank.details.nonEuCompliantDeals]}
                        labels={['EU Compliant', 'Non-EU Compliant']}
                        colors={['#9cc4e3', '#487eb2']}
                        sections={[true, false]}
                        features={[SubscriptionFeature.DealerProfileStatistics, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]}
                    />
                    <StatisticsChart
                        title="Static Deals"
                        onSectionClick={redirectToIMWithStaticDeals}
                        values={[selectedBank.details.staticDeals, selectedBank.details.nonStaticDeals]}
                        labels={['Static', 'Non-Static']}
                        colors={['#9cc4e3', '#487eb2']}
                        sections={[true, false]}
                        features={[SubscriptionFeature.DealerProfileStatistics, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]}
                    />
                    <StatisticsChart
                        title="Out of NC"
                        onSectionClick={redirectToIMWithOutOfNC}
                        values={[selectedBank.details.outOfNC, selectedBank.details.notOutOfNC]}
                        labels={['Yes', 'No']}
                        colors={['#9cc4e3', '#487eb2']}
                        sections={[true, false]}
                        features={[SubscriptionFeature.DealerProfileStatistics, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]}
                    />
                    <StatisticsChart
                        title="Out of RI"
                        onSectionClick={redirectToIMWithOutOfRI}
                        values={[selectedBank.details.outOfRI, selectedBank.details.notOutOfRI]}
                        labels={['Yes', 'No']}
                        colors={['#9cc4e3', '#487eb2']}
                        sections={[true, false]}
                        features={[SubscriptionFeature.DealerProfileStatistics, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]}
                    />
                </div>
            </div>
            {aggregatedManagersCount &&
                <ManagersTransactionCount
                    companyReferenceName={selectedBank.referenceName}
                    companyLegalName={selectedBank.legalName}
                    managerTransactions={aggregatedManagersCount}
                />
            }
            <ShowFor roles={roles.getAllRolesExclude(...roles.bd())} overrideRequiredFeatures={ownCompany}>
                <BankArrangerPipeline companyReferenceName={selectedBank.referenceName} />
            </ShowFor>
            <BankDeals companyReferenceName={selectedBank.referenceName} />
        </div>
    );
}

import { useEffect, useState } from 'react';
import { OriginatingTransaction } from '../../types/amr-pipeline/models/OriginatingTransaction';
import { RequestState } from '../../constants/request-state';
import { amrPipelineService } from '../../services/amr-pipeline.service';
import { isRequestNone } from '../../utils';
import { amrArrangerPipelineService } from '../../services/amr-arranger-pipeline.service';
import { constants, roles } from '../../constants';
import { ArrangerPipeline } from '../../types/amr-pipeline/models/ArrangerPipeline';
import { News } from '../../types/news/News';
import { user } from '../../user';
import { newsService } from '../../services';
import { NewsCategory } from '../../types/news/enums/NewsCategory';
import { BWICPxTalk } from '../../types/banks/BWICPxTalk';
import { banksService } from '../../services/banks.service';

export function useDeals(companyReferenceName?: string) {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [transactions, setTransactions] = useState<OriginatingTransaction[]>([]);

    useEffect(() => {
        if (companyReferenceName && isRequestNone(requestState)) {
            const doRequest = async () => {
                setRequestState(RequestState.request);
                try {
                    const transactions = await amrPipelineService.getBankTransactions(companyReferenceName);

                    setTransactions(transactions);
                    setRequestState(RequestState.success);
                } catch (e) {
                    setRequestState(RequestState.failure);
                }
            }

            doRequest();
        }
    }, [requestState, companyReferenceName])

    return { requestState, transactions };
}

export function useArrangerPipeline(companyReferenceName?: string) {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [pipelines, setPipelines] = useState<ArrangerPipeline[]>([]);

    useEffect(() => {
        if (companyReferenceName && isRequestNone(requestState)) {
            const doRequest = async () => {
                setRequestState(RequestState.request);
                try {
                    const pipelines = await amrArrangerPipelineService.getArrangerPipelines(
                        {
                            arrangerReferenceNames: [companyReferenceName],
                            count: constants.MAX_BACKEND_INT,
                            offset: 0,
                        },
                        constants.defaultArrangerPipelineOrderCriteria
                    );

                    setPipelines(pipelines);
                    setRequestState(RequestState.success);
                } catch (e) {
                    setRequestState(RequestState.failure);
                }
            }

            doRequest();
        }
    }, [requestState, companyReferenceName])

    return { requestState, pipelines };
};

export const useBankNews = (companyReferenceName: string) => {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [news, setNews] = useState<News[]>([]);

    useEffect(() => {
        if (user.hasRoles(roles.Media)) {
            return;
        }

        if (!companyReferenceName || !isRequestNone(requestState)) {
            return;
        }

        const doRequest = async () => {
            setRequestState(RequestState.request);

            try {
                const news = await newsService.getNews({
                    arrangerReferenceNames: [companyReferenceName],
                    categories: [
                        NewsCategory.Primary,
                        NewsCategory.Secondary,
                        NewsCategory.Research,
                    ]
                });

                setNews(news);
                setRequestState(RequestState.success);
            } catch (e) {
                setRequestState(RequestState.failure);
            }
        }

        doRequest();
    }, [requestState, companyReferenceName])

    return { requestState, news };
};

export const useBWICsPriceTalks = (companyReferenceName: string) => {
    const [requestState, setRequestState] = useState(RequestState.none);
    const [bwics, setBwics] = useState<BWICPxTalk[]>([]);

    useEffect(() => {
        if (!companyReferenceName || !isRequestNone(requestState)) {
            return;
        }

        const doRequest = async () => {
            setRequestState(RequestState.request);

            try {
                const bwics: BWICPxTalk[] = await banksService.getBwicsPriceTalks(companyReferenceName);

                setBwics(bwics);
                setRequestState(RequestState.success);
            } catch (e) {
                setRequestState(RequestState.failure);
            }
        }

        doRequest();
    }, [requestState, companyReferenceName])

    return { requestState, bwics };
};

import { isNil } from 'lodash';
import * as Yup from "yup";
import { errorMessages } from '../../../constants';

export const BanksValidationSchema = Yup.object().shape({
    headOfDesk: Yup.object().shape({
        email: Yup
            .string()
            .optional()
            .email(errorMessages.invalidEmail),
        distributionListEmail: Yup
            .string()
            .optional()
            .email(errorMessages.invalidEmail),
    }),
    headOfTrading: Yup.object().shape({
        firstName: Yup
            .string()
            .optional()
            .nullable(),
        lastName: Yup
            .string()
            .optional()
            .nullable(),
        email: Yup
            .string()
            .when(['firstName', 'lastName'], {
                is: (firstName?: string, lastName?: string) => !isNil(firstName) || !isNil(lastName),
                then: () => Yup
                    .string()
                    .typeError(errorMessages.empty)
                    .required(errorMessages.empty)
                    .email(errorMessages.invalidEmail),
                otherwise: () => Yup
                    .string()
                    .optional()
                    .nullable()
                    .email(errorMessages.invalidEmail),
            }),
        distributionListEmail: Yup
            .string()
            .optional()
            .email(errorMessages.invalidEmail),
    })
});

import { IColumnDefinition } from '../../../../bidding/common/table/types/ColumnDefinition';
import { ArrangerPipeline } from '../../../../../types/amr-pipeline/models/ArrangerPipeline';
import { ColumnBuilder } from '../../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import {
    lastUpdatedColumn as defaultLastUpdatedColumn,
    dealNameColumn as defaultDealNameColumn,
    managerColumn as defaultManagerColumn,
    transactionTypeColumn as defaultTransactionTypeColumn,
    collateralTypeColumn as defaultCollateralTypeColumn,
    nonCallPeriodEndColumn as defaultNonCallPeriodEndColumn,
    reinvestmentPeriodEndColumn as defaultReinvestmentPeriodEndColumn,
    expectedTimingColumn as defaultExpectedTimingColumn,
    dealStageColumn as defaultDealStageColumn,
    euComplianceColumn as defaultEuComplianceColumn,
    anchorColumn as defaultAnchorColumn,
    equityColumn as defaultEquityColumn,
    disclosureFileColumn as defaultDisclosureFileColumn,
    managerPresentationFileColumn as defaultManagerPresentationFileColumn,
} from '../../../../amrPipeline/aggregated/arranger-pipeline/arranger-pipeline.columns';
import { SORTING_TYPE } from '../../../../../constants';

const lastUpdatedColumn: IColumnDefinition<ArrangerPipeline> = {
    ...defaultLastUpdatedColumn,
    sortingType: SORTING_TYPE.date,
};

const dealNameColumn: IColumnDefinition<ArrangerPipeline> = {
    ...defaultDealNameColumn,
    sortingField: 'dealLegalName',
};

const managerColumn: IColumnDefinition<ArrangerPipeline> = {
    ...defaultManagerColumn,
    sortingField: 'collateralManager',
    sortingType: SORTING_TYPE.company,
};

const transactionTypeColumn: IColumnDefinition<ArrangerPipeline> = {
    ...defaultTransactionTypeColumn,
    sortingField: 'type',
    sortingType: SORTING_TYPE.string,
};

const collateralTypeColumn: IColumnDefinition<ArrangerPipeline> = {
    ...defaultCollateralTypeColumn,
    sortingType: SORTING_TYPE.string,
};

const nonCallPeriodEndColumn: IColumnDefinition<ArrangerPipeline> = {
    ...defaultNonCallPeriodEndColumn,
    sortingType: SORTING_TYPE.number,
};

const reinvestmentPeriodEndColumn: IColumnDefinition<ArrangerPipeline> = {
    ...defaultReinvestmentPeriodEndColumn,
    sortingType: SORTING_TYPE.number,
};

const expectedTimingColumn: IColumnDefinition<ArrangerPipeline> = {
    ...defaultExpectedTimingColumn,
    sortingType: SORTING_TYPE.string,
};

const dealStageColumn: IColumnDefinition<ArrangerPipeline> = {
    ...defaultDealStageColumn,
    sortingType: SORTING_TYPE.string,
};

const euComplianceColumn: IColumnDefinition<ArrangerPipeline> = {
    ...defaultEuComplianceColumn,
    sortingType: SORTING_TYPE.boolean,
};

const anchorColumn: IColumnDefinition<ArrangerPipeline> = {
    ...defaultAnchorColumn,
    sortingType: SORTING_TYPE.boolean,
};

const equityColumn: IColumnDefinition<ArrangerPipeline> = {
    ...defaultEquityColumn,
    sortingType: SORTING_TYPE.string,
};

const columns: IColumnDefinition<ArrangerPipeline>[] = [
    lastUpdatedColumn,
    dealNameColumn,
    managerColumn,
    transactionTypeColumn,
    collateralTypeColumn,
    nonCallPeriodEndColumn,
    reinvestmentPeriodEndColumn,
    expectedTimingColumn,
    dealStageColumn,
    euComplianceColumn,
    anchorColumn,
    equityColumn,
    defaultDisclosureFileColumn,
    defaultManagerPresentationFileColumn,
];

export const bankArrangerPipelineColumns = columns.map(c => new ColumnBuilder(c));

import * as React from 'react';
import classNames from 'classnames';
import { numericUtils } from '../../../utils';
import { FormError } from '../../controls';
import { BlockedFeatureContext } from '../../access/BlockedFeatureContext';
import { constants } from '../../../constants';

interface Props {
    originalCommission: number
    commission?: string
    disabled?: boolean
    error?: string
    onCommissionChange: (commission: string) => void
    onLeave?: () => void
}

export function Commission({ originalCommission, commission, disabled = false, error = '', onCommissionChange, onLeave }: Props) {
    const isBlocked = React.useContext(BlockedFeatureContext);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (!value || numericUtils.isNumber(value)) {
            onCommissionChange(value);
        }
    };

    const handleBlur = () => {
        if (commission) {
            onCommissionChange((+commission).toFixed(constants.commissionDecimalPlaces));
        } else {
            onCommissionChange(originalCommission.toFixed(constants.commissionDecimalPlaces));
        }

        if (onLeave) {
            onLeave();
        }
    };

    return (
        <div className="form-control-wrapper commision">
            <input
                type="text"
                className={classNames('form-control form-control-sm', { 'is-invalid': !!error})}
                disabled={disabled || isBlocked}
                maxLength={8}
                value={commission}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
            <FormError message={error} />
        </div>
    );
}

import { pick, sum, values } from 'lodash';
import { SectionContent } from '../../../amrPipeline/common/section/SectionContent'
import { PercentageBar } from '../../../amrPipeline/detailed/new-issue/analytics/PercentageBar';
import { RequiredFeature } from '../../../access/RequiredFeature';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { BlockedFeatureContent, SubscribeLink } from '../../../access/BlockedFeatureText';
import { BankAnalytics } from '../../../../types/banks/BankAnalytics';

interface ViewedTabsProps {
    data: BankAnalytics;
}

export const ViewedTabs = ({ data }: ViewedTabsProps) => {
    const totalViews = sum(values(pick(data, [
        'viewedTabsContactsEvents',
        'viewedTabsPrimary',
        'viewedTabsSecondary'
    ])));

    return (
        <SectionContent title="Viewed Tabs">
            <RequiredFeature feature={SubscriptionFeature.DealerProfileAnalytics} renderBlockedContent={() =>
                <div className="viewed-tabs-item">
                    <PercentageBar title="Contacts & Events" maxValue={totalViews} value={data.viewedTabsContactsEvents} />
                    <BlockedFeatureContent
                        inline
                        className="empty-placeholder analytics-legend"
                        text={
                            <>
                                <h3>See more tabs viewed by the clients</h3>
                                <SubscribeLink />
                            </>
                        }
                    />
                </div>
            }>
                <div className="viewed-tabs-item">
                    <PercentageBar title="Contacts & Events" maxValue={totalViews} value={data.viewedTabsContactsEvents} />
                </div>
                <div className="viewed-tabs-item">
                    <PercentageBar title="Primary" maxValue={totalViews} value={data.viewedTabsPrimary} />
                </div>
                <div className="viewed-tabs-item">
                    <PercentageBar title="Secondary" maxValue={totalViews} value={data.viewedTabsSecondary} />
                </div>
            </RequiredFeature>
        </SectionContent>
    );
}

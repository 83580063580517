import { useCallback, useEffect, useRef } from 'react';
import { Redirect, Route, Switch, useHistory, useParams } from "react-router";
import { Preloader } from "../common";
import { BanksSelectionPanel } from "./BanksSelectionPanel";
import { routes } from "../../constants";
import { BanksTabType } from '../../types/banks/BanksTabType';
import { head, isNil } from 'lodash';
import { BankViewPage } from './BankViewPage';
import { BankEditPage } from './edit/BankEditPage';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { BanksState } from '../../types/state/BanksState';
import { banksActions } from '../../actions/banks.actions';
import BanksHeader from './BanksHeader';
import BanksContext from './banksContext';
import { canEditBank, hasBankAnalyticsAccess, isBrokerDealersOwnCompany } from '../../utils/amr-pipeline.utils';
import { BanksAlertsPopup } from "./BanksAlertsPopup";
import { imUserConfigActions } from "../../actions";
import { SubscriptionType } from '../../types/billing/SubscriptionType';

const EditTab = 'edit';

interface BanksRouteParams {
    referenceName: string;
    tab?: BanksTabType | typeof EditTab;
}

export const Banks = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { referenceName, tab } = useParams<BanksRouteParams>();
    const {
        data,
        isLoading,
        isDetailsLoading,
        selectedBank,
        bankReferenceNameForAlert
    } = useSelector<AppState, BanksState>(state => state.banks);
    const userCompany = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon.userCompany);
    const submitHandler = useRef<() => Promise<any>>();
    const bankForAlerts = data?.find(d => d.referenceName === bankReferenceNameForAlert);

    const banksLoaded = !isNil(data);
    const bankLegalName = selectedBank?.legalName;

    const contextValue = {
        canEdit: canEditBank(selectedBank, userCompany),
        ownCompany: isBrokerDealersOwnCompany(selectedBank, userCompany),
        hasAnalyticsAccess: hasBankAnalyticsAccess(selectedBank, userCompany),
        hasProArrangerSubscripion: selectedBank?.details?.subscription === SubscriptionType.pro,
    };

    const navigateToFirstBank = useCallback((tab?: BanksTabType) => {
        const firstBank = head(data);

        if (firstBank) {
            history.replace(routes.manageBanksUrl(firstBank.referenceName, tab || BanksTabType.ContactsAndEvents));
        }
    }, [history, data]);

    useEffect(() => {
        if (bankLegalName) {
            document.title = `${bankLegalName} - KTX ATS Platform`;
        }
    },[bankLegalName, tab]);

    useEffect(() => {
        if (!tab && referenceName) {
            history.replace(routes.manageBanksUrl(referenceName, BanksTabType.ContactsAndEvents));
        }

        if(!data) {
            dispatch(banksActions.getBanks(userCompany?.referenceName));
            return;
        }

        if (!tab && !referenceName) {
            navigateToFirstBank();
        }
    }, [dispatch, data, userCompany?.referenceName, tab, referenceName, history, navigateToFirstBank]);

    useEffect(() => {
        if (!referenceName || !banksLoaded) {
            return;
        }

        dispatch(banksActions.getBankDetails(referenceName));
    }, [dispatch, referenceName, banksLoaded]);

    useEffect(() => {
        if (referenceName || selectedBank) {
            return;
        }

        navigateToFirstBank(tab as BanksTabType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, referenceName, history, selectedBank]);

    useEffect(() => {
        dispatch(imUserConfigActions.getUserConfig());

        return () => {
            dispatch(banksActions.reset());
        };
    }, [dispatch]);

    const handleViewBank = (referenceName: string) => {
        history.replace(routes.manageBanksUrl(referenceName));
    };

    if (!banksLoaded && !isLoading) {
        return <Redirect to={routes.notFound} />;
    }

    const handleEdit = () => {
        return history.replace(routes.manageBanksUrl(referenceName, EditTab));
    };

    const handleCancel = () => {
        return history.replace(routes.manageBanksUrl(referenceName));
    };

    const handleSave = () => {
        if (submitHandler.current) {
            submitHandler.current();
        }
    };

    return (
        <BanksContext.Provider value={contextValue}>
            <div className="container container-banks">
                <Preloader inProgress={isLoading}>
                    <div className="container-flex container-sidebar">
                            <BanksSelectionPanel
                                data={data || []}
                                selectedReferenceName={referenceName}
                                onChange={handleViewBank}
                            />
                            <div className="container-flex content-part-sidebar container-clo-managers container-banks">
                                <Preloader inProgress={isDetailsLoading} fullScreen={false}>
                                    {selectedBank &&
                                        <BanksHeader
                                            bank={selectedBank}
                                            edit={tab === EditTab}
                                            onEdit={handleEdit}
                                            onCancel={handleCancel}
                                            onSave={handleSave}
                                        />
                                    }
                                    <Switch>
                                        <Route
                                            exact
                                            path={routes.manageBanksUrl(':referenceName', EditTab)}
                                            render={() =>
                                                selectedBank && <BankEditPage companyReferenceName={selectedBank.referenceName} setSubmitHandler={(handler) => submitHandler.current = handler} bank={selectedBank} />
                                            }
                                        />
                                        <Route
                                            exact
                                            path={routes.manageBank}
                                            render={() =>
                                                selectedBank && <BankViewPage bank={selectedBank} />
                                            }
                                        />
                                        {referenceName && <Redirect to={routes.manageBanksUrl(referenceName)} />}
                                    </Switch>
                                </Preloader>
                            </div>
                            {bankForAlerts && (
                                <BanksAlertsPopup
                                    selectedBank={bankForAlerts}
                                    onClose={() => dispatch(banksActions.setBankForAlertPopup(undefined))}
                                />
                            )}
                    </div>
                </Preloader>
            </div>
        </BanksContext.Provider>
    );
};

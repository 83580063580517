import { Table } from '../../../../bidding/common/table';
import { useBWICsPriceTalks } from '../../../hooks';
import { bwicsPxTalkColumns } from './bwicsPxTalk.columns';
import { SORT, routes } from '../../../../../constants';
import { EmptyPlaceholder, OnHoverTooltip, Preloader } from '../../../../common';
import { isRequesting } from '../../../../../utils';
import IconSVG from '../../../../../styles/svg-icons';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import { BWICPxTalk } from '../../../../../types/banks/BWICPxTalk';
import { BwicMonitorSortByOptions } from '../../../../../types/state/AllBwicsState';
import { dateFrom, identifiers, sorting } from '../../../../../utils/filtering/serializers/serializer.definitions';
import { QueryStringArgumentSerializer } from '../../../../../utils/filtering/serializers/QueryStringArgumentSerializer';
import { queryStringSerializer } from '../../../../../utils/filtering';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import BanksContext from '../../../banksContext';

interface BWICsPxTalkProps {
    companyCode: string;
    companyReferenceName: string;
}

export const BWICsPxTalk = ({ companyCode, companyReferenceName }: BWICsPxTalkProps) => {
    const { ownCompany } = useContext(BanksContext);
    const { requestState, bwics } = useBWICsPriceTalks(companyReferenceName);

    const createLinkToBwicMonitor = (bwic: BWICPxTalk) => {
        const searchParams = {
            isinCusipsAndTickers: [bwic.ticker],
            dateFrom: new Date(),
            sorting: BwicMonitorSortByOptions.NewestFirst,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            dateFrom(),
            sorting(),
            identifiers(),
        ];

        const queryString = queryStringSerializer.serialize(searchParams, serializer);

        return `${routes.allBWICs}/?${queryString}`;
    };

    return (
        <SectionContent
            title={
                <>
                    BWICs Px Talk
                    <OnHoverTooltip overlay="Today & Upcoming">
                        <IconSVG name="info" width={16} height={16} />
                    </OnHoverTooltip>
                </>
            }
            actionBtn={
                <Link className="btn-link" to={routes.allBWICs} target="_blank">
                    <IconSVG name="go-to" width={16} height={16} />
                    BWIC Monitor
                </Link>
            }
            className="deals"
        >
            <Preloader inProgress={isRequesting(requestState)}>
                {bwics.length ? (
                    <Table
                        dataItems={bwics}
                        createHeaderCustomArgs={() => ({
                            companyCode
                        })}
                        createSecurityCustomArgs={() => ({
                            companyReferenceName,
                            createLinkToBwicMonitor,
                            ownCompany,
                        })}
                        columns={bwicsPxTalkColumns}
                        sticky
                        defaultSortBy="bwicBidsDueDateUtc"
                        defaultSortByDirection={SORT.DESC}
                    />
                ) : (
                    <EmptyPlaceholder text="There is no information to display." />
                )}
            </Preloader>
        </SectionContent>
    )
};

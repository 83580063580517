import { formatUtils } from '../../utils/format.utils';
import { AxedIcon } from '../bidding/common/AxedIcon';
import { FinalIcon } from '../bidding/common/FinalIcon';

interface Props {
    level: number;
    axed: boolean;
    final: boolean;
    commission: number;
}

export function MyBidRequest({ level, axed, final, commission }: Props) {
    return (
        <div className="flex-row justify-content-end">
            {formatUtils.formatBid(level)}
            <div className="commision">{formatUtils.formatDecimal(commission, 4)}</div>
            <div className="axed-final-icons">
                {axed && <AxedIcon />}
                {final && <FinalIcon />}
            </div>
        </div>
    );
}

import IconSVG from '../../../styles/svg-icons';
import { FormField } from '../../amrPipeline/common/FormField';
import { JoditEditor } from '../../common/JoditEditor';
import { Checkbox } from '../../controls';

interface EventItemProps {
    companyReferenceName: string;
    description: string;
    published: boolean;
    onChange: (value: string, published: boolean) => void;
    onDelete: () => void;
}

export const EventItem = ({ companyReferenceName, description, published, onChange, onDelete }: EventItemProps) => (
    <FormField
        title="Event Description"
        capitalize={false}
        className="data-item-row-full-height"
    >
        <JoditEditor
            maxLength={10000}
            value={description}
            uploadPath={`/api/bwic/companies/${companyReferenceName}/bank/image`}
            onChange={(value) => onChange(value, published)}
            placeholder="Add Event Description"
        />
        <div className="row-event-controls">
            <Checkbox label="Save Draft" checked={!published} onChange={(e) => onChange(description, !e.target.checked)} />
            <button onClick={onDelete} className="btn-link btn-danger" disabled={!description}>
                <IconSVG name="basket" width={16} height={16} />
                Delete Event
            </button>
        </div>
    </FormField>
);

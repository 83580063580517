import { useDispatch, useSelector } from "react-redux";
import { Table } from "../../../../bidding/common/table";
import { managersTransactionColumns } from "./managersTransactionCount.columns";
import { EmptyPlaceholder, OnHoverTooltip } from "../../../../common";
import { ManagersTransactionCountFilter } from './ManagersTransactionCountFilter';
import { useState } from 'react';
import { defaultFilterValues } from '../../../../../constants/banks/managerTransactionsFilter';
import { ManagersTransactionAggregated } from '../../../../../types/banks/ManagersTransactionAggregated';
import { ManagersTransactionCountFilterValues } from '../../../../../types/banks/ManagersTransactionCountFilterValues';
import { banksActions } from '../../../../../actions/banks.actions';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import IconSVG from '../../../../../styles/svg-icons';
import { arrangers, collateralManagers } from '../../../../../utils/filtering/serializers/serializer.definitions';
import { queryStringSerializer } from '../../../../../utils/filtering';
import { SORT, constants, routes } from '../../../../../constants';
import { QueryStringArgumentSerializer } from '../../../../../utils/filtering/serializers/QueryStringArgumentSerializer';
import { BankAccessType } from '../../../../../types/amr-pipeline/enums/BankAccessType';
import { ActionBlocker } from '../../../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';
import ExportButton from "../../../../amrPipeline/common/ExportButton";
import { AppState } from "../../../../../types/state/AppState";
import { BanksState } from "../../../../../types/state/BanksState";
import { CollateralType } from "../../../../../types/amr-pipeline/enums/CollateralType";
import { Currency } from "../../../../../types/enums/Currency";
import { useSortedList } from "../../../../../effects";
import { compareStrings } from "../../../../../utils/compare.utils";

interface ManagersTransactionCountProps {
    companyReferenceName: string;
    companyLegalName: string;
    managerTransactions: ManagersTransactionAggregated[];
}

export const ManagersTransactionCount = ({
    companyReferenceName,
    companyLegalName,
    managerTransactions 
}: ManagersTransactionCountProps) => {
    const dispatch = useDispatch();
    const { exportTransactionCountLoading } = useSelector<AppState, BanksState>((state: AppState) => state.banks);
    const [filterChanged, setFilterChanged] = useState(false);
    const [filter, setFilter] = useState(defaultFilterValues);
    const [orderBy, setOrderBy] =useState({
        field: "totalCount",
        direction: SORT.DESC
    })

    const [sortedManagerTransactions] = useSortedList(
        orderBy.field === 'collateralManager'
            ? managerTransactions
            : managerTransactions.sort((a,b) => compareStrings(a.collateralManager, b.collateralManager)), // Additional alphabetic sorting for items with equal values 
        orderBy.field,
        orderBy.direction
    );

    const setSortParams = (field: string, direction: string) => 
        setOrderBy({
            direction,
            field,
        });

    const handleFilterChange = (filter: ManagersTransactionCountFilterValues, filterChanged: boolean) => {
        setFilterChanged(filterChanged);
        setFilter(filter);
        dispatch(banksActions.managersTransactionCountFilter(filter));
    };

    const handleExport = () => {
        dispatch(banksActions.exportTransactionCountRequest(
            companyLegalName,
            companyReferenceName,
            {
                collateralTypes: filter.collateralType === constants.allListItem ? undefined : [filter.collateralType as CollateralType],
                currencyCodes: filter.currency === constants.allListItem ? undefined : [filter.currency as Currency],
                year: filter.year,
                orderBy: {
                    field: orderBy.field,
                    ascending: orderBy.direction === SORT.ASC
                }
            }
        ));
    }

    const redirectToIssuanceMonitor = (manager: ManagersTransactionAggregated) => {
        const searchParams = {
            arrangers: [companyReferenceName],
            collateralManagers: [manager.companyReferenceName],
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            arrangers(),
            collateralManagers(),
        ];

        const queryString = queryStringSerializer.serialize(searchParams, serializer);

        dispatch(banksActions.logUserActivity(companyReferenceName, BankAccessType.PrimaryTotalTransactionsDoor));

        window.open(`${window.location.origin}${routes.AMRPipeline}/?${queryString}`);
    };

    return (
        <SectionContent
            title={
                <>
                    Managers Transaction Count
                    <OnHoverTooltip overlay="Priced & Closed">
                        <IconSVG name="info" width={16} height={16} />
                    </OnHoverTooltip>
                    <ActionBlocker feature={SubscriptionFeature.DealerProfileTransactionsCount}>
                        {blocked => (
                            <ManagersTransactionCountFilter
                                onChange={handleFilterChange}
                                disabled={blocked}
                                {...filter}
                            />
                        )}
                    </ActionBlocker>
                </>
            }
            actionBtn={
                <ExportButton
                    handleExport={handleExport}
                    isExportLoad={exportTransactionCountLoading}
                    disabled={!sortedManagerTransactions.length}
                />
            }
            className="managers-transaction-count"
        >
            {sortedManagerTransactions.length ? (
                <Table
                    onSort={setSortParams}
                    className="data-list data-list-striped data-list-overflow-auto clo-manager-investment-team-table"
                    columns={managersTransactionColumns}
                    defaultSortBy={orderBy.field}
                    defaultSortByDirection={orderBy.direction}
                    dataItems={sortedManagerTransactions}
                    createSecurityCustomArgs={() => ({ redirectToIssuanceMonitor })}
                />
            ) : (
                <EmptyPlaceholder text={filterChanged ? 'No data is available for the selected filters.' : 'There is no information to display.' } />
            )}
        </SectionContent>
    );
};

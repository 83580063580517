import { useSelector } from 'react-redux';
import { BWICsPxTalk } from './bwicsPxTalk/BWICsPxTalk';
import { AppState } from '../../../../types/state/AppState';
import { BanksState } from '../../../../types/state/BanksState';

export const Secondary = () => {
    const { selectedBank } = useSelector<AppState, BanksState>(state => state.banks);

    if (!selectedBank) {
        return null;
    }

    return (
        <BWICsPxTalk companyCode={selectedBank.code} companyReferenceName={selectedBank.referenceName} />
    );
};

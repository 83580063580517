import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { EmptyPlaceholder, OnHoverTooltip, Preloader } from '../../../../common';
import { Table } from '../../../../bidding/common/table';
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';
import { useDeals } from '../../../hooks';
import { isRequesting } from '../../../../../utils';
import { dealColumns } from './bankDeals.columns';
import { SORT, routes } from '../../../../../constants';
import { Transaction } from '../../../../../types/amr-pipeline/models/Transaction';
import { OriginatingTransaction } from '../../../../../types/amr-pipeline/models/OriginatingTransaction';
import IntexPopup from '../../../../amrPipeline/detailed/IntexPopup';
import { AppState } from '../../../../../types/state/AppState';
import IconSVG from '../../../../../styles/svg-icons';
import { queryStringSerializer } from '../../../../../utils/filtering';
import { arrangers, transactionStatuses } from '../../../../../utils/filtering/serializers/serializer.definitions';
import { TransactionStatus } from '../../../../../types/amr-pipeline/enums/TransactionStatus';
import { QueryStringArgumentSerializer } from '../../../../../utils/filtering/serializers/QueryStringArgumentSerializer';
import { banksActions } from '../../../../../actions/banks.actions';
import { BankAccessType } from '../../../../../types/amr-pipeline/enums/BankAccessType';
import { convertToTableItem } from '../../../../clo-managers/tabs/deals/columns';

interface BankDealsProps {
    companyReferenceName: string;
}

export const BankDeals = ({ companyReferenceName }: BankDealsProps) => {
    const dispatch = useDispatch();
    const [transactionForIntex, setTransactionForIntex] = useState<OriginatingTransaction>();
    const { userCompany } = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon);

    const { requestState, transactions } = useDeals(companyReferenceName);

    const redirectToIssuanceMonitor = () => {
        const searchParams = {
            arrangers: [companyReferenceName],
            transactionStatuses: [
                TransactionStatus.Active,
                TransactionStatus.Priced,
                TransactionStatus.OnHold,
                TransactionStatus.Closed
            ],
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            transactionStatuses(),
            arrangers(),
        ];

        const queryString = queryStringSerializer.serialize(searchParams, serializer);

        dispatch(banksActions.logUserActivity(companyReferenceName, BankAccessType.PrimaryIssuanceMonitor));

        window.open(`${window.location.origin}${routes.AMRPipeline}/?${queryString}`);
    };

    const logColumnClick = (bankAccessType: BankAccessType) => () => {
        dispatch(banksActions.logUserActivity(companyReferenceName, bankAccessType));
    };

    return (
        <>
            <SectionContent
                title={
                    <>
                        Deals
                        <OnHoverTooltip overlay="Active & Priced">
                            <IconSVG name="info" width={16} height={16} />
                        </OnHoverTooltip>
                    </>
                }
                actionBtn={
                    <button className="btn-link" onClick={redirectToIssuanceMonitor}>
                        <IconSVG name="go-to" width={16} height={16} />
                        Issuance Monitor
                    </button>
                }
                className="deals"
            >
                <Preloader inProgress={isRequesting(requestState)}>
                    {transactions.length ? (
                        <Table
                            className={classNames('data-list-striped data-list-arranger-pipeline')}
                            dataItems={transactions.map(convertToTableItem)}
                            createSecurityCustomArgs={() => ({
                                setTransactionForIntex,
                                userCompany,
                                onLastUpdatedClick: logColumnClick(BankAccessType.PrimaryDealsLastUpdated),
                                onVersionHistoryClick: logColumnClick(BankAccessType.PrimaryDealsLastUpdatedTransactionVersion),
                                onManagerClick: logColumnClick(BankAccessType.PrimaryDealsManagersName),
                            })}
                            columns={dealColumns}
                            sticky
                            defaultSortBy="closingDate"
                            defaultSortByDirection={SORT.DESC}
                            createRowCustomKey={(t: Transaction) => t.referenceName}
                        />
                    ) : (
                        <EmptyPlaceholder text="There are no transactions yet." />
                    )}
                </Preloader>
            </SectionContent>
            {transactionForIntex && (
                <IntexPopup
                    transaction={transactionForIntex}
                    omitArrangerProOverride={transactionForIntex.status === TransactionStatus.Priced}
                    onCloseClick={() => setTransactionForIntex(undefined)}
                />
            )}
        </>
    );
}

import { SORTING_TYPE } from '../../../../../constants';
import { ColumnBuilder } from '../../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../../bidding/common/table/types/ColumnDefinition';
import { ProportionBar } from '../../../../amrPipeline/ProportionBar';
import { dateTimeUtils } from '../../../../../utils';
import { OnHoverTooltip } from '../../../../common';
import FollowUp from '../../../../common/FollowUp';
import { ProfileViewHistoryPopover } from '../ProfileViewHistoryPopover/ProfileViewHistoryPopover';
import { BankProfileView } from '../../../../../types/amr-pipeline/models/BankSession';
import { BankAccessTypes } from '../../../../../types/amr-pipeline/enums/BankAccessType';

const profileViewHistoryColumnsDefinition: IColumnDefinition<BankProfileView>[] = [
    {
        columnKey: 'profileViews',
        renderColumnHeaderContent: () => 'Profile Views',
        renderColumnContent: (userSession) => (
            <ProfileViewHistoryPopover userSession={userSession}>
                {dateTimeUtils.utcToLocalString(userSession.accessDateTime)}
            </ProfileViewHistoryPopover>
        ),
        sortingField: 'accessDateTime',
        className: 'data-list-cell-lg',
        sortingType: SORTING_TYPE.date,
    },
    {
        columnKey: 'tabsViewed',
        renderColumnHeaderContent: () => 'Tabs Viewed',
        renderColumnContent: profileSession => {
            const views = profileSession.views;

            return (
                <ProportionBar
                    title="view(s)"
                    items={BankAccessTypes.map(t => ({
                        title: t.text,
                        value: views && profileSession.views[t.value],
                        color: t.color
                    }))}
                />
            );
        },
        className: 'data-list-cell-lg-02',
    },
    {
        columnKey: 'fullName',
        renderColumnHeaderContent: () => 'Full Name',
        renderColumnContent: (profileSession) => (
            <ProfileViewHistoryPopover userSession={profileSession}>
                {profileSession.userFullName}
            </ProfileViewHistoryPopover>
        ),
        sortingField: 'userFullName',
        className: 'data-list-cell-xl',
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: 'companyLegalName',
        renderColumnHeaderContent: () => 'Company',
        renderColumnContent: ({ companyLegalName }) => (
            <OnHoverTooltip overlay={companyLegalName}>
                {companyLegalName}
            </OnHoverTooltip>
        ),
        sortingField: 'companyLegalName',
        className: 'data-list-cell-xl-xxl',
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: 'follow-up',
        renderColumnHeaderContent: () => '',
        renderColumnContent: (profileSession) => <FollowUp email={profileSession.userEmail} />,
        className: 'data-list-cell-md-flexible',
    }
];

export const profileViewHistoryColumns = () => profileViewHistoryColumnsDefinition.map(c => new ColumnBuilder(c));


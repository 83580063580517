import { constants } from '../../../../constants'
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { FormField } from '../../../amrPipeline/common/FormField'
import { SectionContent } from '../../../amrPipeline/common/section/SectionContent'
import { BlockedFeatureContent, SubscribeLink } from '../../../access/BlockedFeatureText';
import { RequiredFeature } from '../../../access/RequiredFeature';
import { BankAnalytics } from '../../../../types/banks/BankAnalytics';

interface GeneralProps {
    data: BankAnalytics;
}

export const General = ({ data }: GeneralProps) => {
    return (
        <SectionContent
            title="General"
            className=""
        >
            <div className="data-item-row-col">
                <FormField
                    infoTooltip="Past 24 hours"
                    title="Recent Profile Views"
                    className="data-item-row-full-height"
                >
                    {data.recentProfileViews || constants.emptyPlaceholder}
                </FormField>
                <RequiredFeature feature={SubscriptionFeature.DealerProfileAnalytics} renderBlockedContent={() =>
                    <BlockedFeatureContent
                        inline
                        className="empty-placeholder analytics-legend"
                        text={
                            <>
                                <h3>See total profile and deal views</h3>
                                <SubscribeLink />
                            </>
                        }
                    />
                }>
                    <FormField
                        title="Total Profile Views"
                        className="data-item-row-full-height"
                    >
                        {data.totalProfileViews || constants.emptyPlaceholder}
                    </FormField>
                    <FormField
                        title="Deal Views"
                        infoTooltip="Count of views from transaction marketing start day to pricing day"
                        className="data-item-row-full-height"
                    >
                        {data.dealViews || constants.emptyPlaceholder}
                    </FormField>
                </RequiredFeature>
            </div>
        </SectionContent>
    );
}

import { AppState } from '../../../../types/state/AppState';
import { useDispatch, useSelector } from 'react-redux';
import { General } from './General';
import { ViewedTabs } from './ViewedTabs';
import { useContext, useEffect } from 'react';
import BanksContext from '../../banksContext';
import { ProfileViewHistoryWidget } from './profileViewHistory/ProfileViewHistoryWidget';
import { TransactionViewHistoryWidget } from '../../../common/manager-analytics/transactionViewHistory/TransactionViewHistoryWidget';
import { EmptyPlaceholder, Preloader } from '../../../common';
import { BanksState } from '../../../../types/state/BanksState';
import { banksActions } from '../../../../actions/banks.actions';
import { ClientsActivityWidget } from './ClientsActivityWidget';
import { BankAccessTypes } from '../../../../types/amr-pipeline/enums/BankAccessType';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';

export const Analytics = () => {
    const dispatch = useDispatch();
    const { hasAnalyticsAccess } = useContext(BanksContext);
    const { analytics: { general, isLoading, profileHistoryViews }, selectedBank } = useSelector<AppState, BanksState>((state: AppState) => state.banks);

    useEffect(() => {
        if (!selectedBank?.referenceName) {
            return;
        }

        dispatch(banksActions.analyticsInit(selectedBank.referenceName));

        return () => {
            dispatch(banksActions.analyticsReset());
        };
    }, [selectedBank?.referenceName, dispatch]);

    if (isLoading) {
        return <Preloader inProgress />;
    }

    if (!general || !selectedBank || !hasAnalyticsAccess) {
        return null;
    }


    const isAnalyticsEmpty =
        !general.dealViews
        && !general.presentationDownloads
        && !general.totalProfileViews
        && !general.transactionViewHistory.items.length
        // Also check these in case user has no access to Banks prouct
        && !profileHistoryViews.data.length
        && !general.recentProfileViews
        && !general.viewedTabsContactsEvents;

    if (isAnalyticsEmpty) {
        return <EmptyPlaceholder text="There is no information to display." />;
    }

    return (
        <div className="component-tabs-analytics">
            <div className="data-item data-item-analytics data-item-general-cols">
                <div className="data-item-content">
                    <div className="data-item-row-col">
                        <General data={general} />
                    </div>
                    <div className="data-item-row-col">
                        <ViewedTabs data={general} />
                    </div>
                </div>
            </div>
            <ClientsActivityWidget companyReferenceName={selectedBank.referenceName} accessTypes={BankAccessTypes} />
            <ProfileViewHistoryWidget companyReferenceName={selectedBank.referenceName} accessTypes={BankAccessTypes} />
            <TransactionViewHistoryWidget data={general} requiredFeature={SubscriptionFeature.DealerProfileAnalytics} />
        </div>
    );
}

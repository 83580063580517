import * as React from 'react'
import { useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { SubmitStatus } from '../../bidding/brokerDealer/confirm-bids/SubmitStatus';
import { isRequesting, isRequestSuccess, isRequestFailed } from '../../../utils/request-state.utils';
import { apiUtils, jsonUtils } from '../../../utils';
import { BidConflict } from '../../../types/bidding/BidConflict';
import { BuyerPosition } from '../../../types/bwic/BuyerPosition';
import { Process } from '../../../types/models/Process';
import { liveBiddingUtils } from '../../../utils/live-bidding.utils';

interface Props {
    position: BuyerPosition;
    process: Process;
    isSubmitting: boolean;
    bidsDueDateUtc: Date;
}

export function SubmitBidRequestStatus({ position, process, isSubmitting, bidsDueDateUtc }: Props) {
    const apiOperation = useSelector((s: AppState) => s.apiOperation.requests.find(r => r.positionId === position.id));
    const liveBiddingStage2Expired = liveBiddingUtils.checkLiveBiddingStage2Expired(process, bidsDueDateUtc, position.latestBidSubmission);

    function parseConflict(jsonText: string) {
        const conflict: BidConflict | null = jsonText ? jsonUtils.tryParse(jsonText) : null;
        return conflict ?? undefined;
    }

    return (
        <SubmitStatus
            ticker={position.ticker}
            trade={position.trade}
            isTradedAway={position.isTradedAway}
            isSubmitting={isSubmitting || isRequesting(apiOperation?.state)}
            isSuccess={isRequestSuccess(apiOperation?.state)}
            isFailed={isRequestFailed(apiOperation?.state)}
            isPass={false}
            conflict={apiOperation?.result?.statusCode === 409 && apiOperation?.result?.response != null
                ? parseConflict(apiOperation?.result?.response)
                : undefined
            }
            stageTransitionConflict={apiOperation?.result?.statusCode === 410 && apiOperation?.result?.response != null
                ? jsonUtils.tryParse(apiOperation?.result?.response)
                : undefined
            }
            isBidOverThemself={apiOperation?.result?.statusCode === 300}
            validationError={apiOperation?.result?.statusCode === 400 && apiOperation?.result?.response != null
                ? apiUtils.parseErrorMessage(apiOperation?.result?.response)
                : undefined
            }
            isLoggedOut={apiOperation?.result?.statusCode === 401}
            process={process}
            liveBiddingStage2Expired={liveBiddingStage2Expired}
        />
    );
}

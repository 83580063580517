import { OverviewSection } from "./OverviewSection";
import { ContactsSection } from "./ContactsSection";
import { OnHoverTooltip } from "../../../../common";
import { dateTimeUtils } from "../../../../../utils";
import { Popover } from "../../../../controls";
import { SubscriptionFeature } from "../../../../../types/billing/SubscriptionFeature";
import { RequiredFeature } from "../../../../access/RequiredFeature";
import { BankProfileView } from '../../../../../types/amr-pipeline/models/BankSession';

interface Props {
    userSession: BankProfileView;
    children?: React.ReactNode;
}

export function ProfileViewHistoryPopover({
    userSession,
    children,
}: Props) {
    const formattedActionDate = dateTimeUtils.utcToLocalString(
        userSession.accessDateTime
    );

    const PseudoLink = (
        <span className="pseudo-link">
            <OnHoverTooltip>{children || formattedActionDate}</OnHoverTooltip>
        </span>
    );

    return (
        <RequiredFeature
            feature={SubscriptionFeature.IssuanceMonitorFullAccess}
            renderBlockedContent={() => (
                <span>{children || formattedActionDate}</span>
            )}
        >
            <Popover
                className="popover-transaction-views"
                title={formattedActionDate}
                label={
                    <span className="name-tag">{userSession.userFullName}</span>
                }
                actionButton={PseudoLink}
                showCloseButton={true}
            >
                <OverviewSection views={userSession.views} />
                <ContactsSection userSession={userSession} />
            </Popover>
        </RequiredFeature>
    );
}

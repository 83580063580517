import { compact } from 'lodash';
import IconSVG from '../../styles/svg-icons';
import { Bank } from '../../types/banks/Bank';
import { OnHoverTooltip } from '../common';
import { BanksAlertsButton } from './BanksAlertsButton';
import { useDispatch } from 'react-redux';
import { banksActions } from '../../actions/banks.actions';

interface Props {
    bank: Bank;
    isAlertOn?: boolean;
    showAlertSection?: boolean;
}

export const BanksLabels = ({ bank, showAlertSection, isAlertOn = false }: Props) => {
    const dispatch = useDispatch();
    const { hasActiveTransactions, sendsPxTalk, existsOnDealerInventory } = bank;

    const activitiesCount = compact([hasActiveTransactions, sendsPxTalk, existsOnDealerInventory]).length;

    const getTooltipOverlay = () => {
        const activities = compact([
            hasActiveTransactions ? 'Primary' : undefined,
            sendsPxTalk ? 'Secondary' : undefined,
            existsOnDealerInventory ? 'Inventory' : undefined
        ]);

        return activities.reduce((acc, activity) => acc ? `${acc}, ${activity}` : `Active${activities.length >= 2 ? ':' : ''} ${activity}`, '')
    };

    return (
        <>
            <div className="sidebar-controls-item sidebar-controls-item-active-deals">
                {!!activitiesCount && (
                    <OnHoverTooltip overlay={getTooltipOverlay()}>
                        <IconSVG name="active-manager" width={16} height={16} />
                        {activitiesCount > 1 && <span>{`(+${activitiesCount - 1})`}</span> }
                    </OnHoverTooltip>
                )}
            </div>
            {showAlertSection && (
                <div className="sidebar-controls-item flex-item-right">
                    <OnHoverTooltip overlay="Bank's Profile Alerts">
                        <BanksAlertsButton
                            isAlertOn={isAlertOn}
                            onClick={() => dispatch(banksActions.setBankForAlertPopup(bank.referenceName))}
                        />
                    </OnHoverTooltip>
                </div>
            )}
        </>
    );
};

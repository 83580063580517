import { EventItem } from './EventItem';
import { CompanyEvent } from '../../../types/banks/CompanyEvent';
import { useEffect } from 'react';
import { stringUtils } from '../../../utils';
import { SectionContent } from '../../amrPipeline/common/section/SectionContent';
import IconSVG from '../../../styles/svg-icons';
import { OnHoverTooltip } from '../../common';

interface EventsProps {
    events: CompanyEvent[];
    companyReferenceName: string;
    onChange: (events: CompanyEvent[]) => void;
}

const MaxEventsCount = 10;

export const EmptyEvent = {
    referenceName: '',
    description: '',
    published: true,
} as CompanyEvent;

export const Events = ({ events, companyReferenceName, onChange }: EventsProps) => {
    useEffect(() => {
        if (!events.length) {
            onChange([EmptyEvent]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events.length]);

    const allEventsEmpty = events.every(event => stringUtils.isHTMLEmpty(event.description));

    const maxEventsCountReached = events.length >= MaxEventsCount;

    const handleChange = (eventIndex: number) => (description: string, published: boolean) => {
        const changedEvents = events.map((event, index) => {
            if (index === eventIndex) {
                return {
                    ...event,
                    description,
                    published,
                };
            }

            return event;
        });

        onChange(changedEvents);
    };

    const handleDelete = (eventIndex: number) => () => {
        const changedEvents = events.filter((_, index) => index !== eventIndex);

        onChange(changedEvents);
    };

    const handleAdd = () => {
        onChange([
            EmptyEvent,
            ...events,
        ]);
    };

    return (
        <SectionContent title="Events" className="data-item-bank-event" actionBtn={
            <OnHoverTooltip
                overlay={`You have reached the maximum limit of ${MaxEventsCount} events. Please delete an existing event before adding a new one.`}
                disabled={!maxEventsCountReached}
            >
                <button
                    className="btn btn-ghost btn-sm"
                    onClick={handleAdd}
                    disabled={allEventsEmpty || maxEventsCountReached}
                >
                    <IconSVG name="plus" width={16} height={16} />
                    Event
                </button>
            </OnHoverTooltip>
        }>
            {events.map((event, index) => (
                <EventItem
                    key={index}
                    companyReferenceName={companyReferenceName}
                    description={event.description}
                    published={event.published}
                    onChange={handleChange(index)}
                    onDelete={handleDelete(index)}
                />
            ))}
        </SectionContent>
    );
}

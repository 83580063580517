import { AumStatisticsItem } from '../../../clo-managers/tabs/overview/AumStatisticsItem'
import { StatisticsItem } from '../../../clo-managers/tabs/overview/StatisticsItem'
import { TotalStatisticsItem } from '../../../clo-managers/tabs/overview/TotalStatisticsItem'
import { routes } from '../../../../constants';
import { TransactionStatus } from '../../../../types/amr-pipeline/enums/TransactionStatus';
import { QueryStringArgumentSerializer } from '../../../../utils/filtering/serializers/QueryStringArgumentSerializer';
import { amr, arrangers, collateralTypes, imPipelineTab, multipleCurrencies, transactionStatuses, transactionTypes } from '../../../../utils/filtering/serializers/serializer.definitions';
import { queryStringSerializer } from '../../../../utils/filtering';
import { IssuanceMonitorTab } from '../../../amrPipeline/types/PipelineTabTypes';
import { Currency } from '../../../../types/enums/Currency';
import { CollateralType } from '../../../../types/amr-pipeline/enums/CollateralType';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { user } from '../../../../user';
import { CompanyStats } from '../../../../types/amr-pipeline/models/CompanyStats';
import { StatisticsChartsTransactionStatuses, StatisticsChartsTransactionTypes } from '../../../clo-managers/tabs/overview/constants';
import { BankAccessType } from '../../../../types/amr-pipeline/enums/BankAccessType';
import { useDispatch } from 'react-redux';
import { banksActions } from '../../../../actions/banks.actions';

interface StatisticsProps {
    companyReferenceName: string;
    stats?: CompanyStats;
}

export const Statistics = ({ companyReferenceName, stats }: StatisticsProps) => {
    const dispatch = useDispatch();
    if (!stats) {
        return null;
    }

    const redirectToIssuanceMonitor = (qsBuilder: (...params: any) => string, bankAccessType: BankAccessType) => (...params: any) => {
        const queryString = qsBuilder(...params);

        if (bankAccessType) {
            dispatch(banksActions.logUserActivity(companyReferenceName, bankAccessType));
        }

        window.open(`${window.location.origin}${routes.AMRPipeline}/?${queryString}`);
    };

    const redirectToArrangerPipeline = redirectToIssuanceMonitor(() => {
        const searchParams = {
            arrangers: [companyReferenceName],
            imPipelineTab: IssuanceMonitorTab.ArrangerPipeline,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            arrangers(),
            imPipelineTab(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, BankAccessType.PrimaryStatUpcomingDealsDoor);

    const redirectToAmrDeals = redirectToIssuanceMonitor(() => {
        const searchParams = {
            arrangers: [companyReferenceName],
            transactionStatuses: StatisticsChartsTransactionStatuses,
            transactionTypes: StatisticsChartsTransactionTypes,
            amr: true,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            arrangers(),
            transactionStatuses(),
            transactionTypes(),
            amr(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, BankAccessType.PrimaryStatAmrDealDoor);

    const redirectToTotalTransactions = redirectToIssuanceMonitor(() => {
        const hasImAccess = user.hasFeatures(SubscriptionFeature.IssuanceMonitorDealUpdateEmailAlerts);

        const searchParams = {
            arrangers: [companyReferenceName],
            // Generate different links for subscribed and unsubscribed users
            // to show `see N more deals` placeholder in particular case
            transactionStatuses: hasImAccess ? StatisticsChartsTransactionStatuses : undefined,
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            arrangers(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, BankAccessType.PrimaryStatTotalTransactionsDoor);

    const redirectToActiveTransactions = redirectToIssuanceMonitor(() => {
        const searchParams = {
            arrangers: [companyReferenceName],
            transactionStatuses: [TransactionStatus.Active],
            transactionTypes: StatisticsChartsTransactionTypes,
        };

        const serializer: QueryStringArgumentSerializer<any>[] = [
            arrangers(),
            transactionStatuses(),
            transactionTypes(),
        ];

        return queryStringSerializer.serialize(searchParams, serializer);
    }, BankAccessType.PrimaryStatActiveTransactionsDoor);

    const redirectToVolume = (currency: Currency, collateralType: CollateralType) =>
        redirectToIssuanceMonitor(() => {
            const hasImAccess = user.hasFeatures(SubscriptionFeature.IssuanceMonitorDealUpdateEmailAlerts);

            const searchParams = {
                arrangers: [companyReferenceName],
                // Generate different links for subscribed and unsubscribed users
                // to show `see N more deals` placeholder in particular case
                transactionStatuses: hasImAccess ? StatisticsChartsTransactionStatuses : undefined,
                transactionTypes: StatisticsChartsTransactionTypes,
                currency: [currency],
                collateralTypes: [collateralType],
            };

            const serializer: QueryStringArgumentSerializer<any>[] = [
                arrangers(),
                transactionStatuses(),
                transactionTypes(),
                multipleCurrencies(),
                collateralTypes(),
            ];

            return queryStringSerializer.serialize(searchParams, serializer);
        }, BankAccessType.PrimaryStatVolDoor);

    return (
        <div className="statistics-row">
            <StatisticsItem
                label="Vol. (USD BSL)"
                showLink={!!stats.usdBslCount}
                redirectRequiredFeatures={[SubscriptionFeature.DealerProfilePieCharts]}
                onLinkClick={redirectToVolume(Currency.USD, CollateralType.broadlySyndicated)}
            >
                <AumStatisticsItem value={stats.usdBslVolume} count={stats.usdBslCount} />
            </StatisticsItem>
            <StatisticsItem
                label="Vol. (USD MM)"
                redirectRequiredFeatures={[SubscriptionFeature.DealerProfilePieCharts]}
                showLink={!!stats.usdMmCount}
                onLinkClick={redirectToVolume(Currency.USD, CollateralType.middleMarket)}
            >
                <AumStatisticsItem value={stats.usdMmVolume} count={stats.usdMmCount} />
            </StatisticsItem>
            <StatisticsItem
                label="Vol. (EUR BSL)"
                redirectRequiredFeatures={[SubscriptionFeature.DealerProfilePieCharts]}
                showLink={!!stats.eurBslCount}
                onLinkClick={redirectToVolume(Currency.EUR, CollateralType.broadlySyndicated)}
            >
                <AumStatisticsItem value={stats.eurBslVolume} count={stats.eurBslCount} />
            </StatisticsItem>
            <StatisticsItem
                label="Vol. (EUR MM)"
                redirectRequiredFeatures={[SubscriptionFeature.DealerProfilePieCharts]}
                showLink={!!stats.eurMmCount}
                onLinkClick={redirectToVolume(Currency.EUR, CollateralType.middleMarket)}
            >
                <AumStatisticsItem value={stats.eurMmVolume} count={stats.eurMmCount} />
            </StatisticsItem>
            <StatisticsItem
                label="Active Trans."
                redirectRequiredFeatures={[SubscriptionFeature.DealerProfilePieCharts]}
                showLink={!!stats.activeTransactions}
                onLinkClick={redirectToActiveTransactions}
            >
                <TotalStatisticsItem count={stats.activeTransactions}/>
            </StatisticsItem>
            <StatisticsItem
                label="Total Trans."
                redirectRequiredFeatures={[SubscriptionFeature.DealerProfilePieCharts]}
                showLink={!!stats.totalTransactions}
                onLinkClick={redirectToTotalTransactions}
            >
                <TotalStatisticsItem count={stats.totalTransactions}/>
            </StatisticsItem>
            <StatisticsItem
                label="Upcoming Deals"
                redirectRequiredFeatures={[SubscriptionFeature.DealerProfilePieCharts, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]}
                showLink={!!stats.totalPipelines}
                onLinkClick={redirectToArrangerPipeline}
            >
                <TotalStatisticsItem count={stats.totalPipelines} />
            </StatisticsItem>
            <StatisticsItem
                label="AMR Deals"
                redirectRequiredFeatures={[SubscriptionFeature.DealerProfilePieCharts, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts]}
                showLink={!!stats.numberOfAmrTransactions}
                onLinkClick={redirectToAmrDeals}
            >
                <TotalStatisticsItem count={stats.numberOfAmrTransactions}/>
            </StatisticsItem>
        </div>
    );
}

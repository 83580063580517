import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../types/state/AppState';
import { TreeSelectOption } from '../../../../controls/TreeSelect';
import { AnalyticsWidget } from '../../../../amrPipeline/common/analytics/AnalyticsWidget';
import { ProfileViewHistoryTable } from '../../../../common/manager-analytics/ProfileViewHistoryTable';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';
import { banksActions } from '../../../../../actions/banks.actions';
import { BankAnalyticsFilter } from '../../../../../types/analytics/AnalyticsFilter';
import { BankAccessTypes } from '../../../../../types/amr-pipeline/enums/BankAccessType';
import { profileViewHistoryColumns } from './profileViewHistory.columns';
import { GetAction, withAnalyticsRequest } from '../../../../common/manager-analytics/withAnalyticsRequest';

interface ProfileViewHistoryWidgetProps {
    companyReferenceName: string;
    isLoaded: boolean;
    users: TreeSelectOption<string>[];
}

const selector = (state: AppState) => state.banks.analytics;

const getAction = (companyReferenceName: string, filter: BankAnalyticsFilter) => {
    return banksActions.profileViewHistoryRequest(
        companyReferenceName,
        filter.date.dateFrom || undefined,
        filter.date.dateTo || undefined,
    );
};

function ProfileViewHistoryWidgetComponent({ companyReferenceName, isLoaded, users }: ProfileViewHistoryWidgetProps) {
    const dispatch = useDispatch();
    const state = useSelector(selector);

    const { profileHistoryViews } = state;
    const { filter, data, numberOfHiddenItems } = profileHistoryViews;

    const handleFilterChange = (filter: BankAnalyticsFilter) => {
        dispatch(banksActions.profileViewHistoryFilterChange(filter));
    };

    return (
        <AnalyticsWidget
            title="Profile View History"
            isLoading={isLoaded}
            users={users}
            onFilterChange={handleFilterChange}
            state={profileHistoryViews}
            tabDefinitions={BankAccessTypes}
            feature={SubscriptionFeature.DealerProfileAnalytics}
        >
            <ProfileViewHistoryTable
                companyReferenceName={companyReferenceName}
                data={data}
                filter={filter}
                numberOfHiddenItems={numberOfHiddenItems}
                columns={profileViewHistoryColumns()}
                requiredFeature={SubscriptionFeature.DealerProfileAnalytics}
            />
        </AnalyticsWidget>
    );
}

export const ProfileViewHistoryWidget = withAnalyticsRequest(
    getAction as GetAction,
    selector,
    (state) => state.profileHistoryViews,
)(ProfileViewHistoryWidgetComponent);

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    alertOptionTooltipText,
    alertOptions,
    analyticAlertOptions,
    secondaryStatsAlertOptions,
} from "../../constants/amr-pipeline/alertOptions";
import { AlertOption } from "../../types/email-preferences/EmailPreferences";
import { AppState } from "../../types/state/AppState";
import {
    CompanyAlertsConfig,
    SecondaryStatsAlertsConfig,
} from "../../types/user-config/UserConfig";
import { Preloader } from "../common";
import { Popup, PopupBody, PopupFooter } from "../controls";
import { EmailPreferencesRadioGroup } from "../profile/tabs/email-preferences/tabs/EmailPreferencesRadioGroup";
import { UserConfigType } from "../../types/user-config/UserConfigType";
import { imUserConfigActions } from "../../actions";
import { Link } from "react-router-dom";
import { routes } from "../../constants";
import { EmailPreferencesTab } from "../profile/tabs/email-preferences/EmailPreferencesContent";
import { alertDescriptions } from "../../constants/banks/alertDescriptions";
import { Bank } from "../../types/banks/Bank";
import { hasBankAnalyticsAlertsAccess, hasBankAnalyticsAlertsChangeAccess } from "../../utils/amr-pipeline.utils";
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { user } from "../../user";
import { bankImApRequiredFeatures } from "../../constants/banks/bankImApRequiredFeatures";

interface Props {
    selectedBank: Bank;
    onClose: () => void;
}

export const BanksAlertsPopup = ({ selectedBank, onClose }: Props) => {
    const dispatch = useDispatch();

    const {
        isUpdating: isUserConfigUpdating,
        isDeleting: isUserConfigDeleting,
    } = useSelector((state: AppState) => state.imUserConfig);

    const userCompany = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon.userCompany);

    const bankAnalyticsConfig = useSelector<AppState, CompanyAlertsConfig>(
        (state: AppState) => state.imUserConfig.bankAnalyticsAlert
    ).value.find((c) => c.companyReferenceName === selectedBank?.referenceName);

    const bankApConfig = useSelector<AppState, CompanyAlertsConfig>(
        (state: AppState) => state.imUserConfig.bankArrangerPipelineAlert
    ).value.find((c) => c.companyReferenceName === selectedBank?.referenceName);

    const bankImConfig = useSelector<AppState, CompanyAlertsConfig>(
        (state: AppState) => state.imUserConfig.bankIssuanceMonitorAlert
    ).value.find((c) => c.companyReferenceName === selectedBank?.referenceName);

    const bankDealerInventoryConfig = useSelector<
        AppState,
        SecondaryStatsAlertsConfig
    >(
        (state: AppState) => state.imUserConfig.bankDealerInventoryAlert
    ).value.find((c) => c.companyReferenceName === selectedBank?.referenceName);

    const withAnalyticsAccess = hasBankAnalyticsAlertsAccess(selectedBank, userCompany);
    const withAnalyticsChangeAccess = hasBankAnalyticsAlertsChangeAccess(selectedBank, userCompany);
    const withDiAccess = user.hasFeatures(SubscriptionFeature.InventoryAlerts);
    const withImApAccess = user.hasAllFeatures(...bankImApRequiredFeatures);

    const [editBankAnalyticsConfig, setEditBankAnalyticsConfig] =
        useState(bankAnalyticsConfig);
    const [editBankApConfig, setEditBankApConfig] = useState(bankApConfig);
    const [editBankImConfig, setEditBankImConfig] = useState(bankImConfig);
    const [editBankDiConfig, setEditBankDiConfig] = useState(
        bankDealerInventoryConfig
    );

    const bankAnalyticsAlert =
        bankAnalyticsConfig?.alertOption || AlertOption.Never;
    const bankApAlert = bankApConfig?.alertOption || AlertOption.Never;
    const bankImAlert = bankImConfig?.alertOption || AlertOption.Never;
    const bankDiAlert =
        bankDealerInventoryConfig?.alertOption || AlertOption.Never;

    const selectedBankAnalyticsAlert =
        editBankAnalyticsConfig?.alertOption || AlertOption.Never;
    const selectedBankApAlert =
        editBankApConfig?.alertOption || AlertOption.Never;
    const selectedBankImAlert =
        editBankImConfig?.alertOption || AlertOption.Never;
    const selectedBankDiAlert =
        editBankDiConfig?.alertOption || AlertOption.Never;

    const isAlertsInProgress = isUserConfigUpdating || isUserConfigDeleting;
    const isInitialAlertsState =
        (withAnalyticsChangeAccess
            ? bankAnalyticsAlert === selectedBankAnalyticsAlert
            : true) &&
        bankApAlert === selectedBankApAlert &&
        bankImAlert === selectedBankImAlert &&
        bankDiAlert === selectedBankDiAlert;
    const isSaveDisabled = isInitialAlertsState || isAlertsInProgress;

    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (!isAlertsInProgress && saving) {
            onClose();
            setSaving(false);
        }
    }, [isAlertsInProgress, saving, onClose]);

    useEffect(() => {
        setEditBankAnalyticsConfig(bankAnalyticsConfig);
        setEditBankApConfig(bankApConfig);
        setEditBankImConfig(bankImConfig);
        setEditBankDiConfig(bankDealerInventoryConfig);
    }, [
        bankAnalyticsConfig,
        bankApConfig,
        bankImConfig,
        bankDealerInventoryConfig,
    ]);

    const handleSave = () => {
        setSaving(true);

        const configs = [
            ...(withAnalyticsChangeAccess && editBankAnalyticsConfig
                ? [
                      {
                          type: UserConfigType.bankAnalyticsAlert,
                          value: editBankAnalyticsConfig,
                      },
                  ]
                : []),
            ...(withImApAccess && editBankApConfig
                ? [
                      {
                          type: UserConfigType.bankArrangerPipelineAlert,
                          value: editBankApConfig,
                      },
                  ]
                : []),
            ...(withImApAccess && editBankImConfig
                ? [
                      {
                          type: UserConfigType.bankIssuanceMonitorAlert,
                          value: editBankImConfig,
                      },
                  ]
                : []),
            ...(withDiAccess && editBankDiConfig
                ? [
                      {
                          type: UserConfigType.bankDealerInventoryAlert,
                          value: editBankDiConfig,
                      },
                  ]
                : []),
        ];

        dispatch(imUserConfigActions.saveCompanyAlerts(configs));
    };

    return (
        <Popup
            title={
                <>
                    <span className="title">Banks Alerts</span>
                    <span className="name-tag text-ellipsis">
                        {selectedBank.legalName}
                    </span>
                </>
            }
            onClose={onClose}
            modalClass="modal-email-preferences"
        >
            <PopupBody className="notifications-center">
                {withAnalyticsAccess && (
                    <div className="controls-wrap">
                        <EmailPreferencesRadioGroup
                            title="Analytics"
                            value={selectedBankAnalyticsAlert}
                            disabled={isAlertsInProgress}
                            alertOptions={analyticAlertOptions}
                            groupNameIndentifier="banks-analytics"
                            description={alertDescriptions.analytics}
                            onChange={(alertOption) =>
                                setEditBankAnalyticsConfig({
                                    ...editBankAnalyticsConfig!,
                                    companyReferenceName:
                                        selectedBank.referenceName,
                                    alertOption,
                                })
                            }
                            alertOptionInfo={{
                                [AlertOption.Daily]:
                                    alertOptionTooltipText.Daily,
                            }}
                            requiredFeature={SubscriptionFeature.DealerProfileEmailAlerts}
                        />
                    </div>
                )}
                <div className="controls-wrap">
                    <EmailPreferencesRadioGroup
                        title="Arrangers Pipeline"
                        value={selectedBankApAlert}
                        disabled={isAlertsInProgress}
                        alertOptions={alertOptions}
                        groupNameIndentifier="banks-ap"
                        description={alertDescriptions.arrangersPipeline}
                        onChange={(a) =>
                            setEditBankApConfig({
                                ...editBankApConfig!,
                                companyReferenceName:
                                    selectedBank.referenceName,
                                alertOption:
                                    (a as AlertOption.Daily) ||
                                    AlertOption.Never,
                            })
                        }
                        alertOptionInfo={{
                            [AlertOption.Daily]: alertOptionTooltipText.Daily,
                            [AlertOption.Instant]:
                                "Alerts are sent once bank’s upcoming transactions are published on Arranger Pipeline",
                        }}
                        requiredFeatures={[SubscriptionFeature.DealerProfileEmailAlerts, SubscriptionFeature.IssuanceMonitorArrangerPipeline]}
                    />
                </div>
                <div className="controls-wrap">
                    <EmailPreferencesRadioGroup
                        title="Issuance Monitor"
                        value={selectedBankImAlert}
                        disabled={isAlertsInProgress}
                        alertOptions={alertOptions}
                        groupNameIndentifier="banks-im"
                        description={alertDescriptions.issuanceMonitor}
                        onChange={(a) =>
                            setEditBankImConfig({
                                ...editBankImConfig!,
                                companyReferenceName:
                                    selectedBank.referenceName,
                                alertOption:
                                    (a as AlertOption.Daily) ||
                                    AlertOption.Never,
                            })
                        }
                        alertOptionInfo={{
                            [AlertOption.Daily]:
                                "Alerts are sent at 5 PM EST every business day excluding holidays if bank’s transactions are published or updated",
                            [AlertOption.Instant]:
                                "Alerts are sent once bank’s transactions are published or updated",
                        }}
                        requiredFeatures={[SubscriptionFeature.DealerProfileEmailAlerts, SubscriptionFeature.IssuanceMonitorDealUpdateEmailAlerts]}
                    />
                </div>
                <div className="controls-wrap">
                    <EmailPreferencesRadioGroup
                        title="Dealer Inventory"
                        value={selectedBankDiAlert}
                        disabled={isAlertsInProgress}
                        alertOptions={secondaryStatsAlertOptions}
                        groupNameIndentifier="banks-di"
                        description={alertDescriptions.dealerInventory}
                        onChange={(a) =>
                            setEditBankDiConfig({
                                ...editBankDiConfig!,
                                companyReferenceName:
                                    selectedBank.referenceName,
                                alertOption:
                                    (a as AlertOption.Instant) ||
                                    AlertOption.Never,
                            })
                        }
                        alertOptionInfo={{
                            [AlertOption.Instant]:
                                "Alerts are sent once bank’s security was added/updated/marked as traded on the Dealer Inventory.",
                        }}
                        requiredFeatures={[SubscriptionFeature.BwicMonitorAlerts]}
                    />
                </div>
            </PopupBody>
            <PopupFooter>
                <Link
                    to={routes.profileNotificationsTab(
                        EmailPreferencesTab.Banks
                    )}
                    className="btn-link margin-r-a"
                >
                    Manage Alerts
                </Link>
                <button className="btn btn-ghost" onClick={onClose}>
                    Cancel
                </button>
                <button
                    className="btn btn-main"
                    onClick={handleSave}
                    disabled={isSaveDisabled}
                >
                    Save {isAlertsInProgress && <Preloader small inProgress />}
                </button>
            </PopupFooter>
        </Popup>
    );
};

import React from 'react';

interface BanksContextProps {
    canEdit: boolean;
    ownCompany: boolean;
    hasAnalyticsAccess: boolean;
    hasProArrangerSubscripion: boolean;
}

const BanksContext = React.createContext<BanksContextProps>({
    canEdit: false,
    ownCompany: false,
    hasAnalyticsAccess: false,
    hasProArrangerSubscripion: false,
});

export default BanksContext;

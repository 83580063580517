import { useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { BanksState } from '../../../../types/state/BanksState';
import { EmptyPlaceholder } from '../../../common';

export const BankEvents = () => {
    const { events } = useSelector<AppState, BanksState>(state => state.banks);

    if (!events.length) {
        return (
            <EmptyPlaceholder
                text="There are no events yet."
                className="data-list-primary-desk"
            />
        );
    }

    return (
        <>
            {events.map(event => {
                return (
                    <div className="events-item" key={event.referenceName}>
                        {!event.published && <div className="label-status label-status-draft">Draft</div>}
                        <div key={event.referenceName} dangerouslySetInnerHTML={{ __html: event.description }} />
                    </div>
                )
            })}
        </>
    );
};

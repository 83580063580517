import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../access/ActionBlocker';
import { AlertsButton } from '../common/AlertsButton';

interface Props {
    isAlertOn: boolean;
    label?: string;
    onClick: () => void;
}

export const BanksAlertsButton = ({
    isAlertOn,
    label,
    onClick
}: Props) => {
    return (
        <ActionBlocker noTooltip features={[
            SubscriptionFeature.DealerProfileEmailAlerts,
            SubscriptionFeature.IssuanceMonitorArrangerPipeline,
            SubscriptionFeature.IssuanceMonitorDealUpdateEmailAlerts,
            SubscriptionFeature.BwicMonitorAlerts,
        ]}>
            {blocked => (
                <AlertsButton
                    isSelected={isAlertOn && !blocked}
                    onClick={onClick}
                    label={label}
                />
            )}
        </ActionBlocker>
    );
};

import classNames from "classnames";
import { OnHoverTooltip } from '../common';
import { forwardRef, useState } from 'react';
import Highlighter from '../amrPipeline/common/Highlighter';
import { BanksLabels } from "./BanksLabels";
import { Bank } from '../../types/banks/Bank';

interface Props {
    selected: boolean;
    searchTerm: string;
    bank: Bank;
    withAlert?: boolean;
    onClick: (referenceName: string) => void;
}

export const BanksNavItem = forwardRef(function BanksNavItem({
    selected,
    searchTerm,
    bank,
    withAlert,
    onClick,
}: Props, ref: React.ForwardedRef<HTMLDivElement>) {
    const [hovered, setHovered] = useState(false);

    const {
        legalName,
        referenceName,
        isUserCompany
    } = bank;

    return (
        <div
            className={classNames(
                "sidebar-nav-list-item sidebar-nav-list-item-extended",
                { hovered },
                { active: selected }
            )}
            ref={ref}
            onClick={() => onClick(referenceName)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div className="flex-row">
                <OnHoverTooltip overlay={legalName}>
                    <span className={classNames('title', { 'title-with-label': isUserCompany })}>
                        <Highlighter
                            searchWords={[searchTerm]}
                            autoEscape={true}
                            textToHighlight={legalName}
                        />
                    </span>
                </OnHoverTooltip>
                {isUserCompany && <span className="bwic-state-my">MY</span>}
            </div>
            <div className="flex-row sidebar-controls">
                <BanksLabels showAlertSection isAlertOn={withAlert} bank={bank} />
            </div>
        </div>
    );
});

import * as React from 'react'
import { useSelector } from 'react-redux';
import { RequestState } from '../../../constants/request-state';
import { BwicPositionBase } from '../../../types/bwic/BwicPositionBase';
import { AppState } from '../../../types/state/AppState';
import { apiUtils, isRequestFailed, isRequesting } from '../../../utils';
import { ReSubmitButton } from '../../bidding/brokerDealer/confirm-bids/ReSubmitButton';

interface Props {
    position: BwicPositionBase;
    isSubmitting: boolean;
    onClick: (positionId: number) => void
}

export function ReSubmitBidRequestButton({ position, isSubmitting, onClick }: Props) {
    const apiOperation = useSelector((s: AppState) => s.apiOperation.requests.find(r => r.positionId === position.id));

    return (
        <ReSubmitButton
            isSubmitting={isSubmitting || isRequesting(apiOperation?.state)}
            isFailed={isRequestFailed(apiOperation?.state)}
            isValidationError={
                apiOperation?.state === RequestState.failure &&
                apiOperation.result?.statusCode === 400 &&
                Boolean(apiOperation.result?.response) &&
                apiUtils.parseErrorMessage(apiOperation.result?.response ?? '') != null}
            hasStageTransitionConflict={false}
            isBidOverThemself={apiOperation?.result?.statusCode === 300}
            hasConflict={apiOperation?.result?.statusCode === 409}
            onClick={() => onClick(position.id)}
        />
    );
}

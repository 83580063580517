import * as React from 'react';
import {
    SettlementAgreementStatus,
    settlementAgreementStatusTitle
} from '../../types/bid-as-dealer/SettlementAgreementStatus';
import { Popover } from '../controls';

interface Props {
    status: SettlementAgreementStatus;
    rejectReason?: string;
}

export function BidAsDealerAgreementStatusLabel({ status, rejectReason }: Props) {
    return (
        <div className="status-wrapper">
            {rejectReason && status === SettlementAgreementStatus.rejected ? (
                <Popover
                    title="Suspension Reason"
                    className="popover-md"
                    actionButton={(
                        <span className={'status status-' + SettlementAgreementStatus[status].toLowerCase()}>
                            <button className="pseudo-link text-capitalize">
                                {settlementAgreementStatusTitle[status]}
                            </button>
                        </span>
                    )}
                >
                    <div className="status-message alert">
                        <i className="icon icon-warning" />
                        <span className="status-message-cnt">{rejectReason}</span>
                    </div>
                </Popover>
            ) : (
                <span
                    className={'status status-' + SettlementAgreementStatus[status].toLowerCase()}
                    onClick={(e) => e.stopPropagation()}
                >
                    <span>{settlementAgreementStatusTitle[status]}</span>
                </span>
            )}
        </div>
    );
}

import moment from 'moment';
import { IColumnDefinition } from '../../../../bidding/common/table/types/ColumnDefinition';
import { ColumnBuilder } from '../../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { SORTING_TYPE, constants } from '../../../../../constants';
import { BwicStatusLabel } from '../../../../common/BwicStatusLabel';
import { BWICPxTalk } from '../../../../../types/banks/BWICPxTalk';
import { formatUtils, moneyUtils } from '../../../../../utils';
import { ManagerLink } from '../../../../amrPipeline/common/ManagerLink';
import IconSVG from '../../../../../styles/svg-icons';
import { OnHoverTooltip } from '../../../../common';
import { Link } from 'react-router-dom';
import { ValueBlocker } from '../../../../access/ValueBlocker';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';
import { ActionBlocker } from '../../../../access/ActionBlocker';

const columns: IColumnDefinition<BWICPxTalk>[] = [
    {
        columnKey: 'bwicStatus',
        renderColumnHeaderContent: () => 'Status',
        renderColumnContent: (bwic: BWICPxTalk) =>
            <BwicStatusLabel status={bwic.status} />,
        className: 'data-list-cell-sm-01 cell-bwic-status',
        sortingField: 'status',
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: 'ticker',
        renderColumnHeaderContent: () => 'Ticker',
        renderColumnContent: (bwic) => bwic.ticker,
        className: 'data-list-cell-lg',
        sortingField: 'ticker',
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: 'manager',
        renderColumnHeaderContent: () => 'Manager',
        renderColumnContent: ({ collateralManager }) => {
            if (collateralManager) {
                return (
                    <ManagerLink
                        referenceName={collateralManager.referenceName}
                        legalName={collateralManager.name}
                    />
                )
            }
            return constants.emptyPlaceholder
        },
        className: 'data-list-cell-xl',
        sortingField: 'collateralManager',
        sortingType: SORTING_TYPE.company,
    },
    {
        columnKey: 'rating',
        renderColumnHeaderContent: () => 'Rtg',
        renderColumnContent: (bwic) =>
            bwic.rating,
        className: 'data-list-cell-xs',
        sortingField: 'rating',
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: 'currency',
        renderColumnHeaderContent: () => 'Ccy',
        renderColumnContent: (bwic) =>
            bwic.currency,
        className: 'data-list-cell-xs',
        sortingField: 'currency',
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: 'size',
        renderColumnHeaderContent: () => 'Size, MM',
        renderColumnContent: (bwic) =>
            moneyUtils.amountToMM(bwic.size),
        className: 'text-right',
        sortingField: 'size',
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: 'bwicStartDate',
        renderColumnHeaderContent: () => (
            <OnHoverTooltip overlay="EST 'Time Zone'">
                BWIC Start Date
                <IconSVG name="info" width={16} height={16} />
            </OnHoverTooltip>
        ),
        renderColumnContent: (bwic) => moment(bwic.bwicBidsDueDateUtc).format(constants.dateTimeFormat),
        className: 'data-list-cell-lg',
        sortingField: 'bwicBidsDueDateUtc',
        sortingType: SORTING_TYPE.date,
    },
    {
        columnKey: 'pxTalk',
        renderColumnHeaderContent: () => 'Px Talk',
        renderColumnContent: (bwic) =>
            formatUtils.formatPxTalk(bwic.pxTalks, constants.emptyPlaceholder),
        className: 'data-list-cell-sm-01 text-right',
    },
    {
        columnKey: 'bofaPxTalk',
        renderColumnHeaderContent: ({ companyCode }) => `${companyCode} Px Talk`,
        renderColumnContent: (bwic, { companyReferenceName, ownCompany }) => {
            const companyPxTalks = bwic.pxTalks.filter(x => x.company.referenceName === companyReferenceName);

            return (
                <ValueBlocker feature={SubscriptionFeature.DealerProfileBanksBWICsPxTalk} overrideRequiredFeatures={ownCompany}>
                    {formatUtils.formatPxTalk(companyPxTalks)}
                </ValueBlocker>
            );
        },
        className: 'data-list-cell-sm-01 text-right'
    },
    {
        columnKey: 'color',
        renderColumnHeaderContent: () => 'Color',
        renderColumnContent: (bwic) => bwic.color
            ? formatUtils.formatColor(bwic.color)
            : constants.emptyPlaceholder,
        className: 'data-list-cell-lg'
    },
    {
        columnKey: 'actions',
        renderColumnHeaderContent: () => 'Actions',
        renderColumnContent: (bwic, { createLinkToBwicMonitor }) => (
            <ActionBlocker feature={SubscriptionFeature.BwicMonitorBwicsData}>
                {blocked => blocked ? (
                    <button className="btn-link disabled" disabled>
                        <IconSVG name="bid" width={16} height={16} />
                    </button>
                ) : (
                    <OnHoverTooltip overlay="View BWIC">
                        <Link
                            target="_blank"
                            to={createLinkToBwicMonitor(bwic)}
                            className="btn-link"
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            <IconSVG name="bid" width={16} height={16} />
                        </Link>
                    </OnHoverTooltip>
                )}
            </ActionBlocker>
        ),
        className: 'data-list-cell-xs text-right padding-r-0',
        stickRight: true,
    }
];

export const bwicsPxTalkColumns = columns.map(c => new ColumnBuilder(c));

import { constants } from '../../../../constants';
import { contactsColumnsLabels } from '../../../../constants/banks/contactsColumnsLabels';
import IconSVG from '../../../../styles/svg-icons';
import { ColumnBuilder } from '../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../bidding/common/table/types/ColumnDefinition';
import { BankContactsColumn } from '../../types/BankContactsColumn';
import { BrokerDealerContact } from '../../../../types/amr-pipeline/models/BrokerDealerContact';
import { compact } from 'lodash';
import { ValueBlocker } from '../../../access/ValueBlocker';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { ContactType } from '../../../../types/amr-pipeline/enums/ContactType';

const bankContactsColumnDefinitions: IColumnDefinition<BrokerDealerContact>[] = [
    {
        columnKey: BankContactsColumn.fullName,
        renderColumnHeaderContent: () => contactsColumnsLabels[BankContactsColumn.fullName],
        renderColumnContent: contact => contact.firstName || contact.lastName ? (
            compact([contact.firstName, contact.lastName]).join(' ')
        ) : constants.emptyPlaceholder,
        className: 'data-list-cell-xl',
    },
    {
        columnKey: BankContactsColumn.linkedIn,
        renderColumnHeaderContent: () => contactsColumnsLabels[BankContactsColumn.linkedIn],
        renderColumnContent: (contact, { onLinkedInClick }) =>
            contact.linkedIn ? (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={contact.linkedIn}
                    className="btn-link btn-linkedin"
                    onClick={onLinkedInClick}
                >
                    <IconSVG name="linkedin-filled" width={16} height={16} />
                </a>
            ) : (
                constants.emptyPlaceholder
            ),
        className: 'data-list-cell-xxxs padding-l-0',
    },
    {
        columnKey: BankContactsColumn.distributionListIcon,
        renderColumnHeaderContent: () => '',
        renderColumnContent: ({ type, headOfTrading }) => {
            if (type === ContactType.DistributionList) {
                return <IconSVG name="distrib" width={16} height={16} />
            }

            if (type === ContactType.HeadOfDesk || headOfTrading) {
                return <IconSVG name="head-of-desk" width={16} height={16} />
            }
        },
        className: 'data-list-cell-xxxs padding-l-0',
    },
    {
        columnKey: BankContactsColumn.title,
        renderColumnHeaderContent: () => contactsColumnsLabels[BankContactsColumn.title],
        renderColumnContent: ({ type, headOfTrading, title }) => {
            if (type === ContactType.DistributionList) {
                return 'Distribution List';
            }

            if (type === ContactType.HeadOfDesk) {
                return 'Head of Desk';
            }

            if (headOfTrading) {
                return 'Head of Trading';
            }

            return title || constants.emptyPlaceholder
        },
        className: 'data-list-cell-xl-xxl',
    },
    {
        columnKey: BankContactsColumn.email,
        renderColumnHeaderContent: () => contactsColumnsLabels[BankContactsColumn.email],
        renderColumnContent: (contact) => contact.email
            ? <a href={`mailto:${contact.email}`}>{contact.email}</a>
            : constants.emptyPlaceholder,
        className: 'data-list-cell-xl-xxl',
    },
    {
        columnKey: BankContactsColumn.officePhoneNumber,
        renderColumnHeaderContent: () => contactsColumnsLabels[BankContactsColumn.officePhoneNumber],
        renderColumnContent: (contact, { ownCompany }) =>
            <ValueBlocker feature={SubscriptionFeature.DealerProfileBankContacts} overrideRequiredFeatures={ownCompany}>
                {contact.officePhoneNumber || constants.emptyPlaceholder}
            </ValueBlocker>,
        className: 'data-list-cell-sm',
    },
    {
        columnKey: BankContactsColumn.mobilePhoneNumber,
        renderColumnHeaderContent: () => contactsColumnsLabels[BankContactsColumn.mobilePhoneNumber],
        renderColumnContent: (contact, { ownCompany }) =>
            <ValueBlocker feature={SubscriptionFeature.DealerProfileBankContacts} overrideRequiredFeatures={ownCompany}>
                {contact.mobilePhoneNumber || constants.emptyPlaceholder}
            </ValueBlocker>,
        className: 'data-list-cell-sm',
    },
];

export const bankContactsColumns = bankContactsColumnDefinitions.map(c => new ColumnBuilder(c));

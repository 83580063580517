import { constants } from "../../../../../constants";
import { BankAccessType, BankAccessTypes } from '../../../../../types/amr-pipeline/enums/BankAccessType';
import { FormField } from "../../../../amrPipeline/common/FormField";
import { SectionContent } from '../../../../amrPipeline/common/section/SectionContent';

interface Props {
    views: Record<BankAccessType, number>;
}

export function OverviewSection({ views }: Props) {
    return (
        <SectionContent title="Overview">
            <FormField
                title="Tabs Viewed"
                value={
                    <ul className="tabs-viewed-list">
                        {views &&
                            BankAccessTypes.map((type) => (
                                <li key={type.value} className="tabs-viewed-item">
                                    <span className="tabs-viewed-label">
                                        {type.text}
                                    </span>
                                    {views[type.value]
                                        ? `${views[type.value]} view(s)`
                                        : constants.emptyPlaceholder}
                                </li>
                            ))}
                    </ul>
                }
                className="data-item-row-full-height data-item-row-title-md"
            />
        </SectionContent>
    );
}

import { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import { SelectionPanelSearch } from '../amrPipeline/common/SelectionPanelSearch';
import { BanksNavItem } from './BanksNavItem';
import { Bank } from '../../types/banks/Bank';
import { AlertOption } from "../../types/email-preferences/EmailPreferences";
import { useSelector } from "react-redux";
import { AppState } from "../../types/state/AppState";
import { UserConfigState } from "../../types/state/UserConfigState";
import { getAlertValues, hasBankAnalyticsAlertsChangeAccess } from "../../utils/amr-pipeline.utils";
import { EmptyPlaceholder } from "../common";
import { SubscriptionFeature } from "../../types/billing/SubscriptionFeature";
import { user } from "../../user";
import { bankImApRequiredFeatures } from "../../constants/banks/bankImApRequiredFeatures";

interface Props {
    data: Bank[];
    selectedReferenceName: string;
    onChange: (referenceName: string) => void;
}

const searchBanks = (banks: Bank[], searchTerm: string) => {
    return banks.filter(({ legalName }) =>
        legalName
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase().trim())
    );
};
export const BanksSelectionPanel = ({
    data,
    selectedReferenceName,
    onChange,
}: Props) => {
    const [collapsed, setCollapsed] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const selectedBankRef = useRef<HTMLDivElement>(null);

    const alertConfigs = useSelector<AppState, UserConfigState>((state: AppState) => state.imUserConfig);
    const userCompany = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon.userCompany);

    const withAnalyticsAccess = hasBankAnalyticsAlertsChangeAccess({ referenceName: selectedReferenceName }, userCompany);
    const withDiAccess = user.hasFeatures(SubscriptionFeature.InventoryAlerts);
    const withImApAccess = user.hasAllFeatures(...bankImApRequiredFeatures);

    const bankAnalyticsConfig = getAlertValues(withAnalyticsAccess ? alertConfigs.bankAnalyticsAlert.value : []);
    const bankApConfig = getAlertValues(withImApAccess ? alertConfigs.bankArrangerPipelineAlert.value : []);
    const bankImConfig = getAlertValues(withImApAccess ? alertConfigs.bankIssuanceMonitorAlert.value : []);
    const bankDealerInventoryConfig = getAlertValues(withDiAccess ? alertConfigs.bankDealerInventoryAlert.value : []);

    useEffect(() => {
        var customEvent = document.createEvent("Events");
        customEvent.initEvent("resize", true, true);
        window.dispatchEvent(customEvent);
    }, [collapsed]);

    useEffect(() => {
        if (selectedReferenceName) {
            selectedBankRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const banks = searchBanks(data, searchTerm);

    const renderBanks = () =>
        banks.map(bank => {
            const { referenceName } = bank;

            const analyticsAlertOption = bankAnalyticsConfig.get(referenceName)?.alertOption;
            const apAlertOption = bankApConfig.get(referenceName)?.alertOption;
            const imAlertOption = bankImConfig.get(referenceName)?.alertOption;
            const dealerInventoryAlertOption = bankDealerInventoryConfig.get(referenceName)?.alertOption;

            const withAlert = [analyticsAlertOption, apAlertOption, imAlertOption, dealerInventoryAlertOption].some(
                alert => alert && alert !== AlertOption.Never,
            );

            return (
                <BanksNavItem
                    ref={referenceName === selectedReferenceName ? selectedBankRef : undefined}
                    key={referenceName}
                    searchTerm={searchTerm}
                    selected={selectedReferenceName === referenceName}
                    bank={bank}
                    onClick={onChange}
                    withAlert={withAlert}
                />
            );
        });

    const renderEmptyPlaceholder = () => <EmptyPlaceholder text="No search results found." textView />;

    return (
        <aside className={classnames('sidebar sidebar-nav sidebar-nav-banks', { collapsed })}>
            <SelectionPanelSearch
                searchTerm={searchTerm}
                collapsed={collapsed}
                placeholder="Search by bank"
                onCollapseClick={setCollapsed}
                onSearch={setSearchTerm}
            />
            <nav className="sidebar-nav-list">{banks.length ? renderBanks() : renderEmptyPlaceholder()}</nav>
        </aside>
    );
};

import { ColumnBuilder } from '../../../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IColumnDefinition } from '../../../../bidding/common/table/types/ColumnDefinition';
import { ManagersTransactionAggregated } from '../../../../../types/banks/ManagersTransactionAggregated';
import { SORTING_TYPE, constants, routes } from '../../../../../constants';
import IconSVG from '../../../../../styles/svg-icons';
import { OnHoverTooltip } from '../../../../common';
import { ActionBlocker } from '../../../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';

const managersTransactionColumnDefinitions: IColumnDefinition<ManagersTransactionAggregated>[] = [
    {
        columnKey: 'collateralManager',
        renderColumnHeaderContent: () => 'Manager',
        renderColumnContent: (manager) => {
            return (
                <OnHoverTooltip overlay={manager.collateralManager}>
                    <a
                        href={`${routes.manageCloManagerUrl(
                            manager.companyReferenceName
                        )}`}
                        target="_blank"
                        rel="noreferrer"
                        className="link-filter text-ellipsis"
                    >
                        {manager.collateralManager}
                    </a>
                </OnHoverTooltip>
            );
        },
        className: 'data-list-cell-xxxxl',
        sortingField: 'collateralManager',
        sortingType: SORTING_TYPE.string,
    },
    {
        columnKey: 'newIssue',
        renderColumnHeaderContent: () => 'New Issue',
        renderColumnContent: (manager) => {
            return manager.newIssueCount || constants.emptyPlaceholder;
        },
        className: 'padding-r-0 text-right',
        sortingField: 'newIssueCount',
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: 'refi',
        renderColumnHeaderContent: () => 'Refi',
        renderColumnContent: (manager) => {
            return manager.refinancingCount || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-xs padding-l-0 text-right',
        sortingField: 'refinancingCount',
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: 'reset',
        renderColumnHeaderContent: () => 'Reset',
        renderColumnContent: (manager) => {
            return manager.resetCount || constants.emptyPlaceholder;
        },
        className: 'data-list-cell-xs padding-l-0 text-right',
        sortingField: 'resetCount',
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: 'total',
        renderColumnHeaderContent: () => 'Total',
        renderColumnContent: (manager, context) => {
            if (!manager.totalCount) {
                return constants.emptyPlaceholder;
            }

            return manager.totalCount
        },
        className: 'data-list-cell-xs padding-l-0 text-right',
        sortingField: 'totalCount',
        sortingType: SORTING_TYPE.number,
    },
    {
        columnKey: 'link',
        renderColumnHeaderContent: () => '',
        renderColumnContent: (manager, context) => {
            if (!manager.totalCount) {
                return '';
            }

            return (
                <ActionBlocker feature={SubscriptionFeature.DealerProfileTransactionsCount}>
                    {blocked => (
                        <OnHoverTooltip overlay="See bank's transactions with manager" disabled={blocked}>
                            <button className="btn-link" disabled={blocked} onClick={() => context.redirectToIssuanceMonitor(manager)}>
                                <IconSVG name="go-to" width={16} height={16} />
                            </button>
                        </OnHoverTooltip>
                    )}
                </ActionBlocker>
            );
        },
        className: 'data-list-cell-xxxs',
    },
];

export const managersTransactionColumns = managersTransactionColumnDefinitions.map(c => new ColumnBuilder(c));
